import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {IMenuLink} from "@/_models/IContent";
import {DragDropHelper} from "@/_services/drag-drop.helper";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {GetPageDisplayClass} from "@/_helpers/Functions";
import {SubSiteContent} from "@/_models/SubsiteContent";

@Component({
  selector: 'app-content-page-arrange-child-pages',
  templateUrl: './page-arrange-child-pages.component.html',
  styleUrls: ['./page-arrange-child-pages.component.css',"../../_forms/forms.css", "../../dragula.css"]
})
export class PageArrangeChildPagesComponent extends AbstractDisplayComponent implements OnInit {
    @Input() ListClass:string="";
    @Input() Parent:IMenuLink;
    @Input() Site: SubSiteContent;
    @Output() onMessageSet: EventEmitter<string> = new EventEmitter();
    @Output() onErrorSet: EventEmitter<string> = new EventEmitter();
    @Output() onStatusReset: EventEmitter<void> = new EventEmitter();
    @Output() onDelete: EventEmitter<string> = new EventEmitter();

    constructor(public dragDropper: DragDropHelper){
        super();
    }

    ngOnInit() {
    }

    TransmissionComplete() {
    }

    public SetErrorMessage(error: string) {
        this.onErrorSet.emit(error);
    }

    public SetMessage(message: string) {
        this.onMessageSet.emit(message);
    }

    public ResetStatus() {
        this.onStatusReset.emit();
    }

    public DeleteItem(){
        this.onDelete.emit();
    }


    GetPageClass(link:IMenuLink){
        return GetPageDisplayClass(link);
    }

}

