import {Component, OnInit} from '@angular/core';
import {SnippetService} from "@/_services/snippet.service";
import {GlobalContributorSnippet} from "@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component";
import {AbstractEditorComponent} from "@/_components/AbstractEditorComponent";
import {ContentService} from "@/_services";

@Component({
  selector: 'app-global-contributors-snippet-management',
  templateUrl: 'global-contributors-snippet-management.component.html',
  styleUrls: ['global-contributors-snippet-management.component.css']
})
export class GlobalContributorsSnippetManagementComponent extends AbstractEditorComponent implements OnInit {
    protected snippetService: SnippetService
    public Contributors: Array<GlobalContributorSnippet>;
    ContributorCategory:string;


    constructor() {
        super();
    }

    public SetErrorMessage(error:string){
        this.AfterErrorReceived(null, error);
    }

    protected GetDependencies(){
        this.snippetService = this.injector.get(SnippetService);
        super.GetDependencies();
    }

    AddNewContributor(){
        this.Contributors.push(new GlobalContributorSnippet("","", false, "",null))
    }

    DeleteSnippetFromLocalCollection(snippet:GlobalContributorSnippet){
        this.Contributors = this.Contributors.filter(x=>x != snippet);
    }


    ngOnInit() {
        this.InitialiseEditorRequirements().then(()=> {
            this.LoadContent().catch(error => {
                this.AfterErrorReceived(error);
            }).finally(() => {
                this.SetFinishedLoadingState();
            });
        });
    }

    LoadContent() : Promise<void>{
        this.SetLoadingState();
        return new Promise<void>((resolve, reject)=>{
            this.ProcessServerRequest(this.snippetService.GetGlobalContributors(this.ContributorCategory)).then(result =>{
                let snippets = new Array<GlobalContributorSnippet>();
                let pTypes = Object.keys(result);
                pTypes.forEach(p => {
                    snippets.push(GlobalContributorSnippet.CreateFrom(result[p]));
                });
                this.Contributors = snippets;
                resolve();
            }).catch(error=>{
                reject(error);
            })
        });
    }
}
