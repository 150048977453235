import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileReference} from "@/_services/file-management.service";
import {PopupInput} from "@/_components/_forms/abstract-popup";
import {DeleteConfirmationComponent} from "@/_components/_forms/delete-confitmation/delete-confirmation.component";
import {AbstractDisplayComponent} from "@/_components/AbstractDisplayComponent";
import {ClipboardService} from "ngx-clipboard";

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.css']
})
export class FileItemComponent extends AbstractDisplayComponent implements OnInit {
  @Input() File: FileReference;
  @Output() onDelete: EventEmitter<FileReference> = new EventEmitter<FileReference>();

  constructor(
      private _clipboardService: ClipboardService) {
    super();
  }

  copy(text: string){
    this._clipboardService.copy(text)
  }

  ngOnInit() {
  }

  protected ProcessDeletion() {
    this.onDelete.emit(this.File);
  }
}