import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from "rxjs";
import {AbstractService} from "@/_services/AbstractService";
import {ITaxonomyVersionReceive, ITaxonomyVersionSubmit} from "@/_models/TaxonomyVersion";
import {IUploader} from "@/_services/UploadService";
import {ConvertTaxonomyVersion} from "@/_helpers/Functions";
import {TaxonomyVersionService} from "@/_services/taxonomyVersion.service";

@Injectable({ providedIn: 'root' })
export class TaxonomyUpdateVersionService extends TaxonomyVersionService implements IUploader<ITaxonomyVersionSubmit>{
    constructor(protected http: HttpClient) {
        super(http);
    }

    Upload(object: ITaxonomyVersionSubmit) {
        return this.UpdateVersion(object);
    }
}