import {ActivatedRoute, Router} from "@angular/router";
import {ContentService} from "@/_services/content.service";
import {IValueWithReferences} from "@/_models/IContent";
import {ISimplePage, SimplePage} from "@/_models/SimplePage";
import * as language from "../../Language/language.json";
import {AbstractDisplayComponent} from "../AbstractDisplayComponent";
import {ImageService} from "@/_services/image.service";
import {AbstractEditorComponent} from "@/_components/AbstractEditorComponent";
import {FileManagementService} from "@/_services/file-management.service";

declare const tinymce: any;

export abstract class AbstractContentEditor extends AbstractEditorComponent {
    //TODO option to inject the fields needed for the editor, so they can be shared amongst editors on the same page instead of re requesting
    protected contentService: ContentService;
    protected router: Router;
    protected imageUploadService:ImageService;
    protected FileManagerService:FileManagementService;
    public editor: any;
    public flatContent: string;
    protected ParentID:string;
    protected SiteName:string;
    public IsItemActive:boolean;
    protected ReferencesEnabled:boolean = true;
    protected EditorHeight:number = 500;
    public IsUploadingFile:boolean = false;

    protected CallBacks : Array<any> = new Array<any>();
    ButtonText = language.GENERIC_SAVE_BUTTON_TEXT;
    ChangesSubmittedText = language.GENERIC_SAVE_SUCCESS;
    ButtonBackText = language.GENERIC_BACK_BUTTON_TEXT;
    ContentStateDisabled = language.CONTENT_DISABLED_STATE;
    ContentStateActive = language.CONTENT_ACTIVE_STATE;


    protected constructor() {
        super();
    }

    private CreateLinkList() {
        let result = [];
        this.FileLinks.forEach(x=>{
            result.push({title: x._id, value: x.savedPath},)
        });
        return result;
    }


    protected GetDependencies(){
        this.contentService = this.injector.get(ContentService);
        this.router = this.injector.get(Router);
        this.imageUploadService = this.injector.get(ImageService);
        this.FileManagerService = this.injector.get(FileManagementService);
    }

    protected GetItemHierarchyFromRoute(route: ActivatedRoute){
        this.ParentID = route.snapshot.paramMap.get('parentid') || null;
        this.SiteName = route.snapshot.paramMap.get('site') || "";
    }

    SetConfig(self) {
        let optionalToolBars ='';
        if(this.ReferencesEnabled){
         optionalToolBars += '| insertreference';
        }

    let Config = {
        base_url: '/tinymce',
        relative_urls : false,
        remove_script_host : true,
        suffix: '.min',

        link_list: this.CreateLinkList(),

        height: this.EditorHeight,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            "paste"
        ],
        paste_as_text: true,
        toolbar:
            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat  | code | image ' + optionalToolBars +' | insertpagelink  link unlink insertAppLink | help',

        images_upload_handler: function (blobInfo, success, failure) {
            console.log(blobInfo.blob());
            console.log("called back");
            const file = blobInfo.blob();
            const data = new FormData();
            data.append('file', file, file.name);

            self.imageUploadService.UploadFile(data).subscribe(url => {
                    const path = url.message;
                    success(path);
                },
                error => {
                    failure('Error: ' + error);
                    return;
                });

        },

        files_upload_handler: function (blobInfo, success, failure) {
            console.log(blobInfo.blob());
            console.log("called back2");
            const file = blobInfo.blob();
            const data = new FormData();
            data.append('file', file, file.name);

            self.imageUploadService.UploadFile(data).subscribe(url => {
                    const path = url.message;
                    success(path);
                },
                error => {
                    failure('Error: ' + error);
                    return;
                });

        },

        file_picker_callback_types: 'file image',

        image_title: true,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        /*
          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
          images_upload_url: 'postAcceptor.php',
          here we add custom filepicker only to Image dialog
        */
        file_picker_types: 'image file',
        /* and here's our custom image picker*/

        init_instance_callback: self.setEditor,


        file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');

            if (meta.filetype == 'file') {
                input.setAttribute('accept', '*');
            }

            // Provide image and alt text for the image dialog
            if (meta.filetype == 'image') {
                input.setAttribute('accept', 'image/*');
            }

            /*
              Note: In modern browsers input[type="file"] is functional without
              even adding it to the DOM, but that might not be the case in some older
              or quirky browsers like IE, so you might want to add it to the DOM
              just in case, and visually hide it. And do not forget do remove it
              once you do not need it anymore.
            */

            input.onchange = function () {


                var file = input.files[0];
                var reader = new FileReader();


                // Provide image and alt text for the image dialog
                if (meta.filetype == 'image') {
                    console.log("uploading image");
                    reader.onload = function () {
                        var id = 'blobid' + (new Date()).getTime();
                        var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        var base64 = (<string>reader.result).split(',')[1];
                        var blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), {title: file.name});
                    };
                }
                else{
                    reader.onload = function () {
                        let formData = new FormData();
                        formData.append('file', file, file.name);
                        self.ProcessServerRequest(self.FileManagerService.UploadFile(formData)).then(result=>{
                            cb(result.message, {title: file.name });
                        }).catch(error=>{
                            self.AfterErrorReceived(error, "Error saving file: "+ file.name);
                        }).finally(()=>{

                        });
                    };
                }

                reader.readAsDataURL(file);
                return;
            };
            input.click();
        },

        setup: (editor) => {
            editor.ui.registry.addButton('insertreference', {
                text: 'Reference',
                onAction: () => {
                    editor.windowManager.open({
                        title: 'Insert a reference',
                        body: {
                            type: 'panel',
                            items: [{
                                type: 'selectbox',
                                name: 'type',
                                label: 'Dropdown',
                                items: [
                                    {text: 'Pubmed reference', value: 'PMID'},
                                    {text: 'Standard reference', value: 'REF'}
                                ],
                                flex: true
                            },
                                {
                                    type: 'input', // component type
                                    name: 'details', // identifier
                                    inputMode: 'text',
                                    label: 'Reference details', // text for the label
                                    placeholder: 'pubmed id or text', // placeholder text for the input
                                    disabled: false, // disabled state
                                    maximized: false // grow width to take as much space as possible
                                }
                            ]
                        },
                        onSubmit: function (api) {
                            // insert markup
                            editor.insertContent('[' + api.getData().type + ':' + api.getData().details + ']');

                            // close the dialog
                            api.close();
                        },
                        buttons: [
                            {
                                text: 'Close',
                                type: 'cancel',
                                onclick: 'close'
                            },
                            {
                                text: 'Insert',
                                type: 'submit',
                                primary: true,
                                enabled: false
                            }
                        ]
                    });
                }
            });

            editor.ui.registry.addMenuButton('insertpagelink', {
                text: 'Page Link',
                fetch: function (callback) {
                    let items = self.CreatePageLinks(editor, self, self.AllPages);
                    callback(items);
                }
            });

            editor.ui.registry.addMenuButton('insertAppLink', {
                text: 'Custom page link',
                fetch: function (callback) {
                    let items = self.CreateApplicationPartLinks(editor, self, self.AllPages);
                    callback(items);
                }
            });
        }
    };

    return Config;
}

    CreatePageLinks(editor, component, pages:Array<ISimplePage>){
    let pageLinks = new Array<Object>();
    const uniqueSites = Array.from(new Set(pages.map((item: any) => item.belongsTo.value)));
    uniqueSites.forEach(site=>{

      let result = {
        type: 'nestedmenuitem',
        text: site,
        getSubmenuItems: function () {
          return component.SiteSubmenuItems(site, editor);
        }};
      pageLinks.push(result);
    });
    return pageLinks;
  }

    CreateApplicationPartLinks(editor, component, appParts:Array<SimplePage>){
        let appLinks = new Array<Object>();
        const uniqueSites = Array.from(new Set(appParts.map((item: any) => item.belongsTo.value)));
        uniqueSites.forEach(site=>{
            let result = {
                type: 'nestedmenuitem',
                text: site,
                getSubmenuItems: function () {
                    return component.SiteApplicationItems(site, editor);
                }};

            appLinks.push(result);
        });
        return appLinks;
    }

    SiteApplicationItems(siteName:string, editor){
        let results = [];
        this.AppLinksPages.filter(page=>page.belongsTo==siteName).forEach(page=>{
            results.push({
                type: 'menuitem',
                text: page.linkTitle,
                onAction: function (_) {
                    editor.selection.setContent('<a href="'+ page.urlFriendlyName.toLowerCase()+'">' + editor.selection.getContent() + '</a>');
                }
            });
        });
        return results;
    }

  SiteSubmenuItems(siteName:string, editor){
    let results = [];
      this.AllPages.filter(page=>page.belongsTo.value==siteName).forEach(page=>{
      results.push({
        type: 'menuitem',
        text: page.linkTitle,
        onAction: function (_) {
          editor.selection.setContent('<a href="'+'/'+page.belongsTo.value.toLowerCase() + '/' + page.urlFriendlyName+'">' + editor.selection.getContent() + '</a>');
        }
      });
    });
    return results;
  }

  protected GetHTMLOutputForSentence(sentence:IValueWithReferences<string>){
    let result=sentence.value;
    let citations="";
    sentence.pubMedCitations.forEach(cite=>citations+='[PMID:'+cite.pmID+']');
    sentence.standardCitations.forEach(cite=>citations+='[REF:'+cite.content+']');
    return result+citations
  }

    protected ConvertBoolToActiveStateValue(input:boolean){
        if(input){
            return this.ContentStateActive;
        }else{
            return this.ContentStateDisabled;
        }
    }


  protected abstract InitialiseContentForDisplayInEditor():Promise<void>;

  protected abstract SubmitToServer():Promise<any>;

  ngOnInit() {
      this.SetLoadingState();
      this.InitialiseEditor();
  }

  private InitialiseEditor(){
      this.InitialiseEditorRequirements().then(()=>{
          this.InitialiseContentForDisplayInEditor();
      }).catch(error=>{
          console.log(error);
          this.AfterErrorReceived(error, "Error getting item.")
      }).finally(()=>{
          this.SetFinishedLoadingState();
      });
  }

    public Submit() : any{
        super.SetLoadingState();
        super.SetStatusMessage(language.GENERIC_SUBMITTED);
        super.Submit();
        this.SubmitToServer().then(result=>{
            super.SetStatusMessage(language.GENERIC_SAVE_SUCCESS)
            this.ProcessReceived(result);
            return result;
        }).catch(error=>{
            super.AfterErrorReceived(error);
        }).finally(()=>{
            super.SetFinishedLoadingState();
        });
    }

    protected ProcessReceived(obj:any){
        //default do nothing, the caller will just return the result;

    }

    protected RedirectToURL(baseURL:string, id:string){
      //TODO add created param to url so the form knows its a new page/link
        this.router.navigate([baseURL+id]);
    }

    public GetSitePages(siteName:string):SimplePage[]{
        return this.AppLinksPages.filter(x=>x.belongsTo == siteName);
    }

    protected UploadFile (file:File, name:string, success, failure) {
        let formData = this.CreateFormDataFromFile(file,name);
        this.ProcessServerRequest(this.imageUploadService.UploadFile(formData)).then(result=>{
            const path = result;
            success(path);
        }).catch(error=>{
                failure('Error: ' + error);
                return;
        });


    }

    private CreateFormDataFromFile(file:File, name:string){
        const formData = new FormData();
        formData.append('file', file, file.name);
        if (name.length > 0) {
            formData.append('name', name);
        }
        return formData;
    }
}