import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-characteristics-home',
  templateUrl: './characteristics-template.component.html',
  styleUrls: ['./characteristics-template.component.css'],
})
export class CharacteristicsTemplateComponent implements OnInit {

  constructor(
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {

    }






}
