import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-characteristic-menu',
  templateUrl: './characteristic-menu.component.html',
  styleUrls: ['./characteristic-menu.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class CharacteristicMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
