import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import { routing } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from '@/_helpers';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import {ReactiveFormsModule} from '@angular/forms';
import { HomeComponent } from './_components/home/home.component';
import { LoginComponent } from './_components/login/login.component';
import { TaxonomyTemplateComponent } from './_components/taxonomy-management/taxonomy-template/taxonomy-template.component';
import { CharacteristicsTemplateComponent } from './_components/characteristic-management/characteristics-template/characteristics-template.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { TaxonomyMenuComponent } from './_components/_menus/taxonomy-menu/taxonomy-menu.component';
import { CharacteristicMenuComponent } from './_components/_menus/characteristic-menu/characteristic-menu.component';
import {CharacteristicsHomeComponent} from '@/_components/characteristic-management/characteristics-home/characteristics-home.component';
import { CharacteristicsEditPropertiesComponent } from './_components/characteristic-management/characteristic-editing/characteristics-edit-properties.component';
import {CharacteristicsEditMetabolismsComponent} from '@/_components/characteristic-management/characteristic-editing/characteristics-edit-metabolisms.component';

import {EnvironmentsEditMetabolismsComponent} from '@/_components/characteristic-management/environment-editing/environments-edit-metabolisms.component';
import {CharacteristicEnvironmentComponent} from '@/_components/_forms/characteristic-environment/characteristic-environment.component';
import {EnvironmentsEditPropertiesComponent} from '@/_components/characteristic-management/environment-editing/environments-edit-properties.component';
import { TaxonomyHomeComponent } from './_components/taxonomy-management/taxonomy-home/taxonomy-home.component';
import { LoadingComponent } from './_components/common/loading/loading.component';
import { TaxonomyVersionAddComponent } from './_components/taxonomy-management/taxonomy-version-add/taxonomy-version-add.component';
import {MicrobeDetailsUpdateComponent} from '@/_components/taxonomy-management/microbe-details-update.component';
import { EditContentPageComponent } from './_components/content-management/edit/edit-content-page.component';
import { FormsModule } from '@angular/forms';
import { ContentComponent } from './_components/content-management/template-content/content.component';
import { ContentMenuComponent } from './_components/_menus/content-menu/content-menu.component';
import { ContentHomeComponent } from './_components/content-management/home-content/content-home.component';
import { ContentArrangeComponent } from './_components/content-management/arrange-home/content-arrange.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import {DndModule} from 'ngx-drag-drop';
import {PageArrangeChildPagesComponent} from '@/_components/content-management/page-arrange-pages/page-arrange-child-pages.component';
import {DragulaModule} from 'ng2-dragula';
import {PageEditIndexPagesComponent} from '@/_components/content-management/page-edit-index-pages/page-edit-index-pages.component';
import { MainMenuComponent } from './_components/_menus/main-menu/main-menu.component';
import { SiteSettingsComponent } from './_components/site-settings/site-settings-template/site-settings.component';

import { SiteSettingIndividualComponent } from './_components/_forms/site-setting-individual/site-setting-individual.component';
import { MatchingComponent } from './_components/taxonomy-management/matching/matching/matching.component';
import {MicrobeMatchingComponent} from '@/_components/taxonomy-management/matching/microbe/microbe-matching.component';
import { PageArrangeSubSiteRootComponent } from './_components/content-management/page-arrange-sub-site-root/page-arrange-sub-site-root.component';
import { ToolbarComponent } from './_components/common/toolbar/toolbar.component';
import { QuillModule } from 'ngx-quill';
import { AddContentComponent } from './_components/content-management/add/add-content.component';
import {DanishAbundanceUpdateComponent} from '@/_components/taxonomy-management/danish-abundance-update.component';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { AbundanceComponent } from './_components/taxonomy-management/danish-abundance-management/abundance.component';
import { ArchiveFileComponent } from './_components/_forms/archive-file/archive-file.component';
import { ExistingFileComponent } from './_components/_forms/existing-file/existing-file.component';
import { TaxonomyVersionListComponent } from './_components/taxonomy-management/taxonomy-version-list/taxonomy-version-list.component';
import { TaxonomyVersionEditComponent } from './_components/taxonomy-management/taxonomy-version-edit/taxonomy-version-edit.component';
import { UpdateDatabaseTaxonomyComponent } from './_components/taxonomy-management/update-database-taxonomy.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ReportManagementComponent } from './_components/document-management/report-management/report-management.component';
import { ReportCategoryComponent } from './_components/document-management/report-category/report-category.component';
import { ReportDocumentComponent } from './_components/document-management/report-document/report-document.component';
import { AddReportComponent } from './_components/document-management/report-document/add-report/add-report.component';
import { AddReportCategoryComponent } from './_components/document-management/report-category/add-report-category/add-report-category.component';
import { EditReportCategoryComponent } from './_components/document-management/report-category/edit-report-category/edit-report-category.component';
import { RegisterUserComponent } from './_components/user-management/register-user/register-user.component';
import { UsersTemplateComponent } from './_components/user-management/users-template/users-template.component';
import { ListUsersComponent } from './_components/user-management/list-users/list-users.component';
import { UsersMenuComponent } from './_components/_menus/users-menu/users-menu.component';
import { ReparesUpdateComponent } from './_components/taxonomy-management/repares-update.component';
import { GlobalAbundanceUpdateADComponent } from './_components/taxonomy-management/global-abundance-update-ad.component';
import { GlobalAbundanceUpdateASComponent } from './_components/taxonomy-management/global-abundance-update-as.component';
import { MicrobeUpdateResponseComponent } from './_components/common/microbe-update-response/microbe-update-response.component';
import { ProcessTypeSnippetManagementComponent } from './_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component';
import { ProcessTypeFormComponent } from './_components/text-snippet-management/process-type-form.component';
import { GlobalContributorComponent } from './_components/text-snippet-management/global-contributor.component';
import { GlobalContributorsSnippetManagementComponent } from './_components/text-snippet-management/global-contributors-snippet-management/global-contributors-snippet-management.component';
import { ContentSnippetManagementComponent } from './_components/content-snippet-management/content-snippet-management.component';
import { TextSnippetComponent } from './_components/text-snippet-management/text-snippet.component';
import { DownloadDetailsComponent } from './_components/taxonomy-management/download-details/download-details.component';
import { AdContributorsComponent } from './_components/text-snippet-management/global-contributors-snippet-management/ad-contributors.component';
import { WwtpsContributorsComponent } from './_components/text-snippet-management/global-contributors-snippet-management/wwtps-contributors.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PageArrangeItemLinkComponent } from './_components/content-management/page-arrange-item-link/page-arrange-item-link.component';
import {EditLinkComponent} from '@/_components/content-management/edit/edit-link.component';
import {AddLinkComponent} from '@/_components/content-management/add/add-link.component';
import { DeleteConfirmationComponent } from './_components/_forms/delete-confitmation/delete-confirmation.component';
import {MatDialog} from '@angular/material';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ArrangeOrphanPagesComponent} from '@/_components/content-management/arrange-orphan-pages/arrange-orphan-pages.component';
import {SuccessComponent} from '@/_components/common/success/success.component';
import {ErrorComponent} from '@/_components/common/error/error.component';
import {TaxonomySettingsComponent} from '@/_components/site-settings/taxonomy-settings/taxonomy-settings.component';
import {SettingsMenuComponent} from '@/_components/_menus/settings-menu/settings-menu.component';
import {GeneralSettingsComponent} from '@/_components/site-settings/general-settings/general-settings.component';
import {BlastSettingsComponent} from '@/_components/site-settings/blast-settings/blast-settings.component';
import { TaxonomyVersionManagementComponent } from './_components/taxonomy-management/taxonomy-version-management/taxonomy-version-management.component';
import {AppInjector} from '@/_services/app-injector.service';
import {ImageService} from '@/_services/image.service';
import { UserListItemComponent } from './_components/user-management/user-list-item/user-list-item.component';
import {EditUserComponent} from '@/_components/user-management/edit-user/edit-user.component';
import {FileManagementService} from '@/_services/file-management.service';
import { FileManagerComponent } from './_components/file-manager/file-manager.component';
import { FilesTemplateComponent } from './_components/file-manager/files-template/files-template.component';
import {FilesMenuComponent} from '@/_components/_menus/files-menu/files-menu.component';
import { FileItemComponent } from './_components/file-manager/file-item/file-item.component';
import {ClipboardModule} from 'ngx-clipboard';
import { GlobalAbundanceManagementComponent } from './_components/taxonomy-management/global-abundance-management/global-abundance-management.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    TaxonomyTemplateComponent,
    CharacteristicsTemplateComponent,
    TaxonomyMenuComponent,
    CharacteristicMenuComponent,
    CharacteristicsHomeComponent,
    CharacteristicsEditPropertiesComponent,
    CharacteristicsEditMetabolismsComponent,
    EnvironmentsEditMetabolismsComponent,
    CharacteristicEnvironmentComponent,
    EnvironmentsEditPropertiesComponent,
    TaxonomyHomeComponent,
    LoadingComponent,
    TaxonomyVersionAddComponent,
    MicrobeDetailsUpdateComponent,
    EditContentPageComponent,
    ContentComponent,
    ContentMenuComponent,
    ContentHomeComponent,
    ContentArrangeComponent,
    PageArrangeChildPagesComponent,
    PageEditIndexPagesComponent,
    MainMenuComponent,
    SiteSettingsComponent,
    SiteSettingIndividualComponent,
    MatchingComponent,
    MicrobeMatchingComponent,
    PageArrangeSubSiteRootComponent,
    ToolbarComponent,
    AddContentComponent,
    DanishAbundanceUpdateComponent,
    AbundanceComponent,
    ArchiveFileComponent,
    ExistingFileComponent,
    TaxonomyVersionListComponent,
    TaxonomyVersionEditComponent,
    UpdateDatabaseTaxonomyComponent,
    ReportManagementComponent,
    ReportCategoryComponent,
    ReportDocumentComponent,
    AddReportComponent,
    AddReportCategoryComponent,
    EditReportCategoryComponent,
    RegisterUserComponent,
    UsersTemplateComponent,
    ListUsersComponent,
    UsersMenuComponent,
    ReparesUpdateComponent,
    GlobalAbundanceUpdateASComponent,
    GlobalAbundanceUpdateADComponent,
    MicrobeUpdateResponseComponent,
    ProcessTypeSnippetManagementComponent,
    ProcessTypeFormComponent,
    GlobalContributorComponent,
    GlobalContributorsSnippetManagementComponent,
    ContentSnippetManagementComponent,
    TextSnippetComponent,
    DownloadDetailsComponent,
    AdContributorsComponent,
    WwtpsContributorsComponent,
    PageArrangeItemLinkComponent,
      EditLinkComponent,
      AddLinkComponent,
      DeleteConfirmationComponent,
      ArrangeOrphanPagesComponent,
      ErrorComponent,
      SuccessComponent,
      TaxonomySettingsComponent,
      SettingsMenuComponent,
      GeneralSettingsComponent,
      BlastSettingsComponent,
      TaxonomyVersionManagementComponent,
      UserListItemComponent,
    EditUserComponent,
    FileManagerComponent,
    FilesTemplateComponent,
    FilesMenuComponent,
    FileItemComponent,
    GlobalAbundanceManagementComponent
  ],
    imports: [

        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        DragDropModule,
        DndModule,
        routing,
        FormsModule, DndModule,
        DragulaModule.forRoot(),
        // QuillModule.forRoot(),
        MatDialogModule,
        EditorModule, MatExpansionModule, BrowserAnimationsModule, MatFormFieldModule, MatCheckboxModule, MatInputModule, MatButtonModule, TypeaheadModule.forRoot(), ClipboardModule
    ], exports: [
        MatFormFieldModule, MatInputModule
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    ImageService,
      FileManagementService

  ],
    entryComponents: [DeleteConfirmationComponent],
  bootstrap: [AppComponent]
})
export class AppModule { constructor(injector: Injector) { AppInjector.setInjector(injector); }}
