import {Component, OnInit} from '@angular/core';
import {UploadService} from "@/_services/UploadService";
import {TaxonomyVersionService} from "@/_services/taxonomyVersion.service";
import {TaxonomyVersionSubmit} from "@/_models/TaxonomyVersion";
import {AbstractUploadComponent} from "@/_components/AbstractUploadComponent";


@Component({
  selector: 'app-taxonomy-update',
  templateUrl: './taxonomy-version-add.component.html',
  styleUrls: ['./taxonomy-version-add.component.css'],
  providers: [{provide: UploadService, useClass: TaxonomyVersionService}]
})
export class TaxonomyVersionAddComponent extends AbstractUploadComponent implements OnInit {
  NewVersion: TaxonomyVersionSubmit = new TaxonomyVersionSubmit();

  LATEST_TYPE="latest";
  ARCHIVE_TYPE="archive";

  constructor(
      protected taxonomyVersionService:TaxonomyVersionService,
  ) {
    super(taxonomyVersionService);
  }

  ngOnInit() {
      this.PreInit()
    this.SetFinishedLoadingState();
  }

    protected GetResponseValue(response: any) {
        return response ? response.value : null;
    }


    handleSelectedRecordType(type){
    if(type == this.LATEST_TYPE){
      this.NewVersion.isArchive = false;
    }
    else if(type == this.ARCHIVE_TYPE){
      this.NewVersion.isArchive = true;
    }
  }

  TransmissionComplete(){
    super.SetStatusMessage("New taxonomy version added");
  }

  ResetForm(){
        this.NewVersion = new TaxonomyVersionSubmit();
  }

    SubmitUpload(){
        this.ResetState();
        this.SetLoadingState();
        if(!this.NewVersion.description){
            this.NewVersion.description = "";
        }
        super.SubmitFile(this.NewVersion).then(()=>{
            this.TransmissionComplete();
        }).finally(()=>{
            super.SetFinishedLoadingState();
            this.ResetForm();
        });
    }
}

