export class User {
    //id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;
    userLevel:string;
}



export class UserDto{
    username: string;
    firstName: string;
    lastname: string;
    userLevel:string;
    //id:string;
}

export class UserCreationDto extends UserDto {
    password: string;
    repeatPassword: string;
}