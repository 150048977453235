import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ReportCategoryDto, ReportsService} from "@/_services/reports.service";
import {ActivatedRoute} from "@angular/router";
import {AbstractDisplayComponent} from "../../../AbstractDisplayComponent";
import {DragDropHelper} from "@/_services/drag-drop.helper";
import {ContentService} from "@/_services";

@Component({
  selector: 'app-add-report-category',
  templateUrl: './add-report-category.component.html',
  styleUrls: ['../../../../app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddReportCategoryComponent extends AbstractDisplayComponent implements OnInit {
  NewCategory:ReportCategoryDto;
    SubSiteNames: Array<string>;
  constructor(
      protected reportService:ReportsService,
      protected route: ActivatedRoute,
      public contentService: ContentService
  ) {
    super();
  }

  ngOnInit() {
    this.SetLoadingState();
    this.InstantiateCategory();
      this.LoadSubSiteNames().then(result=>{
          this.SubSiteNames = result;
      }).finally(()=>{
          super.SetFinishedLoadingState();
      });

  }

    protected LoadSubSiteNames():Promise<string[]>{
        return this.ProcessServerRequest<string[]>(this.contentService.GetSubSiteNames());
    }


  private InstantiateCategory() {
    this.NewCategory = new ReportCategoryDto();

    let parent = this.route.snapshot.paramMap.get('parent');
    if(parent){
        this.NewCategory.parent = parent;
    }else{
        this.NewCategory.parent = null;
    }

    let site = this.route.snapshot.paramMap.get('site');
    if(site){
      this.NewCategory.site = site;
    }else{
      this.NewCategory.site = null;
    }

    this.NewCategory.description = "";
  }

  Save(){
    super.Submit();
    super.SetLoadingState();
    this.ProcessServerRequest(this.reportService.NewCategory(this.NewCategory)).then(()=>{
      super.SetStatusMessage("Category added successfully");
    }).catch(error=>{
      super.AfterErrorReceived(error, "Error creating report category.");
    }).finally(()=>{
      super.SetFinishedLoadingState();
    });
  }
}
