import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UploadService} from "@/_services/UploadService";
import {ImageService} from "@/_services/image.service";
import {ContentService} from "@/_services/content.service";
import {ActivatedRoute} from "@angular/router";
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {IContentPage} from "@/_models/IContent";
import * as language from "../../../Language/language.json";
import {IsActive} from "@/_helpers/Functions";
import {getTinymce} from "@tinymce/tinymce-angular/TinyMCE";

@Component({
  selector: 'app-content-edit',
  templateUrl: '../content-editor-template.html',
  styleUrls: ['../content-editing.css'],
    providers: [{provide: UploadService, useClass: ImageService}],
    encapsulation:ViewEncapsulation.None
})
export class EditContentPageComponent extends AbstractContentEditor implements OnInit {

    Title="Edit Page: ";
    ButtonText = language.GENERIC_SAVE_BUTTON_TEXT;
    ChangesSavedText = language.GENERIC_SAVE_SUCCESS;
    public Page:IContentPage;

    constructor(
        protected contentService:ContentService,
        protected route: ActivatedRoute
        ) {
        super();
    }

    protected SubmitToServer():Promise<void>{
        this.PrepareForSubmission();
        return this.ProcessServerRequest<void>(this.contentService.UpdatePage(this.Page));
    }

    private PrepareForSubmission(){
        this.Page.flatContent = this.flatContent;
        if(this.IsItemActive){
            this.Page.state.value = this.ContentStateActive;
        }else{
            this.Page.state.value = this.ContentStateDisabled;
        }
    }

    protected FlattenContent():Promise<void>{
        return new Promise((resolve,reject)=>{
            let result='';
            this.Page.contents.forEach(sentence=>result+=this.GetHTMLOutputForSentence(sentence));
            this.flatContent = result;
            resolve();
        });
    }

    //TODO refactor to use base class methods

    protected GetPage() :Promise<IContentPage> {
        let ID: string = this.route.snapshot.paramMap.get('id');
        return super.ProcessServerRequest<IContentPage>(this.contentService.GetSinglePage(ID));
    }

    protected InitialiseContentForDisplayInEditor():Promise<void>{
        return new Promise((resolve,reject)=> {
            this.GetPage().then(result => {
                if(result){
                    this.Page = result;
                    this.Title = this.Title + result.linkTitle;
                    this.FlattenContent();
                    this.IsItemActive = IsActive(this.Page);
                    resolve();
                }
                reject();
            },error=>{
                reject(error);
            });
        });
    }
}
