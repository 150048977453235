import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";
import {IContentPage} from "@/_models/IContent";
import {Injectable} from "@angular/core";
import {AbstractService} from "@/_services/AbstractService";
import {ISimplePage} from "@/_models/SimplePage";
import {
    IGlobalContributorSnippet,
    IProcessTypeSnippet, ITextSnippet
} from "@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component";
import {IDictionary} from "@/_models/microbe";

@Injectable({ providedIn: 'root' })
export class SnippetService extends AbstractService{
    constructor(protected http: HttpClient) {
        super(http, "snippets");
    }

    public GetProcessTypes() : Observable<IDictionary<IProcessTypeSnippet>>{
        return this.http.get<IDictionary<IProcessTypeSnippet>>(`${this.apiURL}processtypes`);
    }

    public UpdateProcessType(value:IProcessTypeSnippet)  : Observable<void>{
        return this.http.patch<void>(`${this.apiURL}processtype`, value);
    }

    public AddProcessType(value:IProcessTypeSnippet)  : Observable<void>{
        return this.http.post<void>(`${this.apiURL}processtype`, value);
    }

    public AddGlobalContributor(value:IGlobalContributorSnippet,category:string)  : Observable<void>{
        return this.http.post<void>(`${this.apiURL}${category}/globalcontributor`, value);
    }

    public DeleteSnippet(value:ITextSnippet)  : Observable<void>{
        return this.http.delete<void>(`${this.apiURL}${value._id}`);
    }

    public DeleteADContributorSnippet(value:ITextSnippet)  : Observable<void>{
        return this.http.delete<void>(`${this.apiURL}ad/globalcontributors/${value._id}`);
    }

    public DeleteWWTPSContributorSnippet(value:ITextSnippet)  : Observable<void>{
        return this.http.delete<void>(`${this.apiURL}wwtps/globalcontributors/${value._id}`);
    }

    public DeleteProcessTypeDescriptionSnippet(value:ITextSnippet)  : Observable<void>{
        return this.http.delete<void>(`${this.apiURL}processtype/${value._id}`);
    }


    public UpdateGlobalContributor(value:IGlobalContributorSnippet,category:string)  : Observable<void>{
        console.log(category);
        return this.http.patch<void>(`${this.apiURL}${category}/globalcontributor`, value);
    }

    public GetGlobalContributors(category:string) : Observable<IDictionary<IGlobalContributorSnippet>>{
        return this.http.get<IDictionary<IGlobalContributorSnippet>>(`${this.apiURL}${category}/globalcontributors`);
    }

    public UpdateCitedSnippet(value:ITextSnippet)  : Observable<void>{
        return this.http.patch<void>(`${this.apiURL}citedsnippet`, value);
    }

    public AddCitedSnippet(value:ITextSnippet)  : Observable<void>{
        value._id = value.name;
        return this.http.post<void>(`${this.apiURL}citedsnippet`, value);
    }

    public GetCitedSnippets() : Observable<IDictionary<ITextSnippet>>{
        return this.http.get<IDictionary<ITextSnippet>>(`${this.apiURL}citedsnippets`);
    }

    public UpdateTextSnippet(value:ITextSnippet)  : Observable<void>{
        return this.http.patch<void>(`${this.apiURL}textsnippet`, value);
    }

    public AddTextSnippet(value:ITextSnippet)  : Observable<void>{
        value._id = value.name;
        return this.http.post<void>(`${this.apiURL}textsnippet`, value);
    }

    public GetTextSnippets() : Observable<IDictionary<ITextSnippet>>{
        return this.http.get<IDictionary<ITextSnippet>>(`${this.apiURL}textsnippets`);
    }



    /*public GetExistingPages() : Observable<IContentPage[]>{
        return this.http.get<IContentPage[]>(`${this.apiURL}all`);
    }

    public GetSinglePage(id:string) : Observable<IContentPage>{
        return this.http.get<IContentPage>(`${this.apiURL}id/${id}`);
    }

    public GetSubsiteNames() : Observable<string[]>{
        return this.http.get<string[]>(`${this.apiURL}sites`);
    }

    public UpdatePages(values:IContentPage[])  : Observable<void>{
        return this.http.post<void>(`${this.apiURL}`, values);
    }

    public UpdatePage(value:IContentPage)  : Observable<void>{
        return this.http.post<void>(`${this.apiURL}id/${value._id}`, value);
    }

    public AddPage(value:IContentPage): Observable<any> {
        return this.http.post<string>(`${this.apiURL}new`, value);
    }

    public GetSimplePages() : Observable<ISimplePage[]>{
        return this.http.get<ISimplePage[]>(`${this.apiURL}structure`);
    }

    public ReorderPages(values:IContentPage[])  : Observable<void>{
        return this.http.post<void>(`${this.apiURL}order`, values);
    }*/
}