import { Component, OnInit } from '@angular/core';
import {AbstractDisplayComponent} from "@/_components/AbstractDisplayComponent";
import {FileManagementService, FileReference} from "@/_services/file-management.service";
import {SubSiteContent} from "@/_models/SubsiteContent";
import {UserDto} from "@/_models";

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent extends AbstractDisplayComponent implements OnInit {
  Files:Array<FileReference>;

  constructor(protected FileManagerService:FileManagementService) {
    super();
  }

  ngOnInit() {
    this.LoadContent();
  }

  private LoadContent(){
    //this.Files = new Array<FileReference>();
    this.ProcessServerRequest(this.FileManagerService.GetAllFiles()).then(files=>{
      this.Files = files;
    }).catch(error=>{
      this.AfterErrorReceived(error, "Error initialising file list.")
    }).finally(()=>{
      this.SetFinishedLoadingState();
    });
  }

  public DeleteFile(file :FileReference){
    this.Submit();
    this.ProcessServerRequest(this.FileManagerService.DeleteFile(file)).then(result=>{
      this.Files = this.Files.filter(x=>x._id != file._id);
      this.SetStatusMessage("File: "+ file._id + " deleted successfully.")
    }).catch(error=>{
      this.AfterErrorReceived(error);
    }).finally(()=>{

    });
  }
}
