import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ReportCategoryDto, ReportsService} from "@/_services/reports.service";
import {ActivatedRoute} from "@angular/router";
import {AbstractDisplayComponent} from "../../../AbstractDisplayComponent";
import {ContentService} from "@/_services";

@Component({
  selector: 'app-edit-report-category',
  templateUrl: './edit-report-category.component.html',
  styleUrls: ['../../../../app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditReportCategoryComponent extends AbstractDisplayComponent implements OnInit {
    SubSiteNames: Array<string>;
  Category:ReportCategoryDto;
  constructor(
              protected reportService:ReportsService,
              protected route: ActivatedRoute,
              public contentService: ContentService
  ) {
    super();
  }

  ngOnInit() {
    this.InstantiateCategory();
  }

    protected LoadSubSiteNames():Promise<string[]>{
        return this.ProcessServerRequest<string[]>(this.contentService.GetSubSiteNames());
    }

  private InstantiateCategory() {
    this.SetLoadingState();
    this.ProcessServerRequest(this.reportService.GetReportCategory(this.route.snapshot.paramMap.get('category'))).then(result=>{
        this.Category = ReportCategoryDto.CreateFromCategory(result);
    }).catch(error=>{
      this.AfterErrorReceived(error,"Error getting category.")
    }).finally(()=>{
        this.LoadSubSiteNames().then(result=>{
            this.SubSiteNames = result;
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });

    });
  }

  Save(){
    super.Submit();
    super.SetLoadingState();
    this.ProcessServerRequest(this.reportService.UpdateCategory(this.Category)).then(()=>{
      super.SetStatusMessage("Category updated successfully");
    }).catch(error=>{
      super.AfterErrorReceived(error, "Error updating report category.");
    }).finally(()=>{
      super.SetFinishedLoadingState();
    });
  }
}
