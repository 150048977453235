import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ReportCategory, ReportsService} from "@/_services/reports.service";
import {DragDropHelper} from "@/_services/drag-drop.helper";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {ContentService} from "@/_services";
import {Content} from "@angular/compiler/src/render3/r3_ast";

@Component({
  selector: 'app-report-category',
  templateUrl: './report-category.component.html',
  styleUrls: ['./report-category.component.css', "../../_forms/forms.css", "../../dragula.css"],
    encapsulation:ViewEncapsulation.None
})
export class ReportCategoryComponent extends AbstractDisplayComponent implements OnInit {
  @Input() Category:ReportCategory;
  expandReportsPanel:boolean = false;
  expandChildCategoriesPanel:boolean = false;
  @Output() OnDelete: EventEmitter<ReportCategory> = new EventEmitter<ReportCategory>();


  //TODO refactor other classes involved in report management

  constructor(
      private reportsService:ReportsService,
      public dragDropper: DragDropHelper,
      protected contentService:ContentService
  ) {
      super();
  }

  ngOnInit() {

  }

  DeleteChildCategory($event){
      this.Category.childCategories = this.Category.childCategories.filter(x=>x != $event);
      this.OnDelete.emit($event);
  }



    protected ProcessDeletion(){
      /*
      What happens when its a nested category?
       */
        this.OnDelete.emit(this.Category);
    }

  //TODO refactor, can probably use a base class method
  public DeleteReport(input: any) {
      this.ResetState();

      this.reportsService.DeleteReport(input.report._id).subscribe(x => {
          this.Category.reports = this.Category.reports.filter(x => x._id != input.report._id);
        super.SetStatusMessage("Report successfully deleted");
      }, error => {
          this.AfterErrorReceived(error,"Error deleting report.");
      });
  }

}
