import {Component, Input, OnInit} from '@angular/core';
import {IMicrobeUpdateResponse} from "@/_components/AbstractUploadComponent";

@Component({
  selector: 'app-update-response',
  templateUrl: './microbe-update-response.component.html',
  styleUrls: ['./microbe-update-response.component.css']
})
export class MicrobeUpdateResponseComponent implements OnInit {
    @Input() Response: IMicrobeUpdateResponse;
  constructor() { }

  ngOnInit() {
  }

  GetResponse(){
    return this.Response;
  }

}
