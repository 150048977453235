import {Component, Input, OnInit} from '@angular/core';
import {UploadService} from "@/_services/UploadService";
import {ReparesService} from "@/_services/repares.service";
import {AbstractFormDataUploadComponent} from "@/_components/common/upload/AbstractFormDataUploadComponent";

@Component({
  selector: 'app-repares-update',
  templateUrl: '../common/upload/upload.component.html',
  styleUrls: ['../common/upload/upload.component.css'],
    providers: [{provide: UploadService, useClass: ReparesService}]
})
export class ReparesUpdateComponent extends AbstractFormDataUploadComponent  implements OnInit {
    @Input() AllowedFileTypes = ".csv"
    Intro = "<h1>Update antimicrobial resistance details(REPARES)</h1>\n" +
        "<p>Upload a new details file below and the details will be automatically updated.</p>\n" +
        "<p>Note: file must be in <strong>.csv</strong> format.</p>"

    constructor(protected service: UploadService) {
        super(service);
    }

    ngOnInit() {
    }
}
