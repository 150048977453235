import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ISetting} from "@/_models";
import {Observable} from "rxjs";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";

@Component({
    selector: 'app-site-setting-individual',
    templateUrl: './site-setting-individual.component.html',
    styleUrls: ['../forms.css']
})
export class SiteSettingIndividualComponent extends AbstractDisplayComponent implements OnInit {
    //@Input() Form: FormGroup;
    Form: FormGroup;
    @Input() ControlName: string;
    @Input() Description: string;
    @Input() Hint: string = "";
    @Input() Observable: Observable<ISetting>;
    @Input() Label: any;
    //@Input() Error:string;
    //@Input() GetError:Function;
    @Output() SubmissionFunction: EventEmitter<ISetting> = new EventEmitter<ISetting>();
    @Input() Validators: any;

    Setting:ISetting;
    VALID = false;
    MODIFIED = false;

    constructor(
        protected formBuilder: FormBuilder) {
        super();
    }

    ngOnInit() {
        this.SetLoadingState();
        this.PreInit();
        this.GetValue().then(value=>{
            if(value == null){
                this.InitialiseBlankValue();
            }else{
                this.Setting = value;
            }
            this.AfterInit();
        }).catch(error=>{
            this.AfterErrorReceived(error, "Unable to get setting from server");
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });

    }

    PreInit() {
        super.PreInit();
        this.InitialiseBlankValue();
    }

    InitialiseForm(){
        let params = {};
        params[this.ControlName] = ['', this.Validators];
        this.Form = this.formBuilder.group(params);
    }

    private AfterInit() {
        this.InitialiseForm();
    }

    InitialiseBlankValue() {
        this.Setting = {title: this.ControlName, value: ""};
    }

    private GetValue() : Promise<ISetting> {
        return this.ProcessServerRequest<ISetting>(this.Observable);
        /*return new Promise<ISetting>((resolve, reject) => {
            this.Observable.pipe(first()).subscribe(value => {
                resolve(value);
            }, () => {
                reject();
            });
        })*/
    }

    MarkModified(){
        this.MODIFIED = true;
    }

    ValidateField() {
        this.MarkModified();
        if (this.i.invalid) {
            this.VALID = false;
            return false;
        }
        this.VALID = true;
        return true;
    }

    SetValue() {
        this.Setting.value = this.Form.controls[this.ControlName].value;
        let valid = this.ValidateField();
        if (valid) {
            this.SubmissionFunction.emit(this.Setting);
        }
    }

    get i() {
        return this.Form.controls[this.ControlName];
    }
}
