import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
    encapsulation:ViewEncapsulation.None
})
export class ContentComponent implements OnInit {

  constructor(
      ) { }

  ngOnInit() {
  }

}
