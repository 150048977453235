import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent} from "@angular/common/http";
import {AbstractService} from "@/_services/AbstractService";
import {Observable} from "rxjs";
@Injectable({ providedIn: 'root' })
export abstract class UploadService extends AbstractService implements IUploader<FormData>{

    protected constructor(protected http: HttpClient, urlExtension:string) {
        super(http,urlExtension);
    }
    abstract UploadFile(values:any);

    public Upload(object: any) {
        return this.UploadFile(object);
    }
}

export interface IUploader<T>{
    Upload(object:T);
}