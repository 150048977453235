import {Component, OnInit} from '@angular/core';
import {SnippetService} from "@/_services/snippet.service";
import {AbstractEditorComponent} from "@/_components/AbstractEditorComponent";

@Component({
    selector: 'app-snippet-management',
    templateUrl: './process-type-snippet-management.component.html',
    styleUrls: ['./process-type-snippet-management.component.css']
})
export class ProcessTypeSnippetManagementComponent extends AbstractEditorComponent implements OnInit {
    public ProcessTypes: Array<ProcessTypeSnippet>;

    constructor(
        protected service: SnippetService) {
        super();
    }

    AddNewProcessType(){
        this.ProcessTypes.push(new ProcessTypeSnippet("","", "","",false,null))
    }

    ngOnInit() {
        this.InitialiseEditorRequirements().then(()=>{
            this.GetProcessTypes();
        });
    }



    public SetErrorMessage(error:string){
        this.AfterErrorReceived(null, error);
    }

    DeleteSnippet(snippet:ProcessTypeSnippet){
        this.ProcessTypes = this.ProcessTypes.filter(x=>x != snippet);
    }

    GetProcessTypes() {
        this.ProcessServerRequest(this.service.GetProcessTypes()).then(result=>{
            let snippets = new Array<ProcessTypeSnippet>();
            let pTypes = Object.keys(result);
            pTypes.forEach(p => {
                snippets.push(ProcessTypeSnippet.CreateFrom(result[p]));
            });
            this.ProcessTypes = snippets;
        });
    }
}

export interface IProcessTypeSnippet extends ITextSnippet {
    title: string;
    shorthand: string;
}

export class TextSnippet implements ITextSnippet {
    text: string;
    name: string;
    existing: boolean;
    _id: string;

    public static CreateFrom(input: ITextSnippet){
        return new TextSnippet(input.text,input.name,true, input._id);
    }

    constructor(text:string, name:string, existing:boolean, id:string) {
        this.text = text;
        this.name = name;
        this.existing = existing;
        this._id = id;
    }
}

export class ProcessTypeSnippet extends TextSnippet implements IProcessTypeSnippet {
    shorthand: string;
    text: string;
    title: string;
    name: string;
    existing:boolean;
    _id: string;

    constructor(shorthand:string = "", text:string = "", title:string = "", name:string = "",existing = false, _id:string){
        super(text, name, existing, _id);
        this.shorthand = shorthand;
        this.title = title;
    }

    public static CreateFrom(input: IProcessTypeSnippet) {
        return new ProcessTypeSnippet(input.shorthand, input.text, input.title, input.name, true, input._id)
    }
}

export interface ITextSnippet {
    name: string;
    text: string;
    existing: boolean;
    _id:string;
}

export interface IGlobalContributorSnippet extends ITextSnippet {
    groupName:string;
    countryName:string;
}

export class GlobalContributorSnippet extends TextSnippet implements IGlobalContributorSnippet{
    groupName:string;
    countryName:string;
    _id: string;

    public static CreateFrom(input: IGlobalContributorSnippet){
        return new GlobalContributorSnippet(input.text,input.countryName, true, input.groupName, input._id);
    }

    constructor(text:string, name:string, existing:boolean, groupName:string, id:string) {
        super(text,name,existing, id);
        this.groupName = groupName;
    }
}


