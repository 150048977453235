import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from "@/_models";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  @Input() currentUser:User;
  @Output() LogoutEmitter: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  Logout(){
    this.LogoutEmitter.emit();
  }

}
