import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UploadService} from "@/_services/UploadService";
import {ImageService} from "@/_services/image.service";
import {ContentService} from "@/_services/content.service";
import {ActivatedRoute} from "@angular/router";
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {IMenuLink} from "@/_models/IContent";
import * as language from "../../../Language/language.json";
import {IsActive} from "@/_helpers/Functions";

@Component({
  selector: 'app-content-edit',
  templateUrl: '../content-link-template.html',
  styleUrls: ['../content-editing.css'],
    providers: [{provide: UploadService, useClass: ImageService}],
    encapsulation:ViewEncapsulation.None
})
export class EditLinkComponent extends AbstractContentEditor implements OnInit {
    Title="Edit Link: ";
    ButtonText = language.GENERIC_SAVE_BUTTON_TEXT;
    public Link:IMenuLink;

    constructor(
                protected contentService:ContentService,
                protected route: ActivatedRoute
                ) {
        super();
    }

    private PrepareForSubmission(){
        if(this.IsItemActive){
            this.Link.state.value = this.ContentStateActive;
        }else{
            this.Link.state.value = this.ContentStateDisabled;
        }
    }

    protected SubmitToServer():Promise<void>{
        this.PrepareForSubmission();
        return this.ProcessServerRequest<void>(this.contentService.UpdateLink(this.Link));
    }


//TODO refactor to use base class methods
    protected GetLink():Promise<void> {
        return new Promise((resolve, reject) => {
            const self = this;
            let ID: string = this.route.snapshot.paramMap.get('id');
            this.contentService.GetSingleLink(ID).subscribe(page => {
                self.Link = page;
                self.Title = self.Title + page.linkTitle;
                resolve();
            });
        });
    }

    protected InitialiseContentForDisplayInEditor():Promise<void>{
        return new Promise((resolve,reject)=> {
            this.GetLink().then(x => {
                this.IsItemActive = IsActive(this.Link);
            });
            resolve();
        });
    }
}
