import {IValueString} from "@/_models/IContent";

export interface ISimplePage {
    urlFriendlyName:string;
    linkTitle:string;
    belongsTo:IValueString;
}

export class SimplePage{
    urlFriendlyName:string;
    linkTitle:string;
    belongsTo:string;

    constructor(url:string, title:string, siteName:string) {
        this.belongsTo = siteName;
        this.linkTitle = title;
        this.urlFriendlyName = url;
    }
}