import {Component, Input, OnInit} from '@angular/core';
import {IReceivedFile} from "@/_models/TaxonomyVersion";

@Component({
  selector: 'app-existing-file',
  templateUrl: './existing-file.component.html',
  styleUrls: ['./existing-file.component.css']
})
export class ExistingFileComponent implements OnInit {
  @Input() ExistingFile:IReceivedFile;
  constructor() { }

  ngOnInit() {
  }

}
