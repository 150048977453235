import {Component, Input, OnInit} from '@angular/core';

import {IContentPage} from "@/_models/IContent";

@Component({
  selector: 'app-edit-index-pages',
  templateUrl: './page-edit-index-pages.component.html',
  styleUrls: ['./page-edit-index-pages.component.css',"../../_forms/forms.css", "../../dragula.css"]
})
export class PageEditIndexPagesComponent implements OnInit {
    @Input() ListClass:string="";
    @Input() Content:Array<IContentPage>;
    @Input() Location:Array<IContentPage>;
    @Input() Parent:IContentPage;

    constructor(){

    }

    GetModel():IContentPage[]{
        if(this.Parent!=undefined){
            return this.Parent.children;
        }
        else{
            return this.Content;
        }
    }

    ngOnInit() {

    }
}

