import { Injectable } from '@angular/core';
import {AbstractService} from "./AbstractService";
import {HttpClient, HttpEvent} from "@angular/common/http";
import {Observable} from "rxjs";
import {ISortable, ITaxonomyVersionSubmit} from "@/_models/TaxonomyVersion";
import {IContentPage} from "@/_models/IContent";
import {UploadService} from "@/_services/UploadService";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ReportsService  extends UploadService{

  constructor(protected http:HttpClient) {
    super(http,"reports");
  }

  public NewReport(report: ReportDto) : Observable<HttpEvent<any>>{
    let formData = this.ConvertReportToFormData(report);
    return this.http.post<any>(`${this.apiURL}`, formData ,{reportProgress: true, observe: 'events'});
  }

    public ReorderCategories(values:IReportCategory[]) : Observable<void>{
        let categories = new Array<ReportCategoryDto>();
          values.map(cat=>{
              categories.push(ReportCategoryDto.CreateFromCategory(cat));
          });
          console.log(categories);
        return this.http.post<void>(`${this.apiURL}order`, categories);
    }

  public NewCategory(category: ReportCategoryDto) : Observable<HttpEvent<any>>{
    return this.http.post<any>(`${this.apiURL}category`, category );
  }

  public UpdateCategory(category: ReportCategoryDto) : Observable<HttpEvent<any>>{
    return this.http.put<any>(`${this.apiURL}category`, category );
  }

  GetReportCategories() : Observable<IReportCategory[]>{
    return this.http.get<IReportCategory[]>(`${this.apiURL}categories`);
  }

  GetReportCategory(id:string) :Observable<IReportCategory>{
    return this.http.get<IReportCategory>(`${this.apiURL}category/${id}`);
  }

  GetReports() : Observable<Array<Report>> {
      return this.http.get<IReport[]>(`${this.apiURL}`);
  }

  public DeleteReport(ID:string){
    return this.http.delete<any>(`${this.apiURL}report/`+ID);
  }

  public DeleteReportCategory(ID:string){
    return this.http.delete<any>(`${this.apiURL}category/`+ID);
  }

  UploadFile(values: ReportDto) {
    return this.NewReport(values);
  }

  private ConvertReportToFormData(report: ReportDto):FormData{
    let formDataVersion = new FormData();
    formDataVersion.append('_id',report._id||null);
    formDataVersion.append('description',report.description);
    formDataVersion.append('category',String(report.category));
    formDataVersion.append('displayName',String(report.displayName));
    formDataVersion.append('Files',report.File);
    return formDataVersion;
  }
}

export interface IReport{
  "description":string,
  "displayName":string,
  "_id":string,
  "category":string
}

export interface IReportCategory extends ISortable{
  "description":string,
  "name":string,
  "_id":string,
  "parent":string,
    "belongsTo":any
}

export class Report{
  description:string;
  displayName:string;
  _id:string;
  category:string;

  constructor(input:IReport) {
    this._id = input._id;
    this.description = input.description;
    this.displayName = input.displayName;
    this.category = input.category;
  }
}

export class ReportDto{
  description:string;
  displayName:string;
  _id:string;
  category:string;
  File:File;

  constructor() {
  }
}

export class ReportCategoryDto{
  description:string;
  name:string;
  _id:string;
  parent:string;
  orderPosition:number;
  site:string;

  constructor() {
      this.orderPosition = -1;
  }

  static CreateFromCategory(input:IReportCategory) : ReportCategoryDto{
        let result = new ReportCategoryDto();
      result._id = input._id;
      result.description = input.description;
      result.name = input.name;
      result.parent = input.parent;
      result.orderPosition = input.orderPosition;
      result.site = input.belongsTo.value;
      return result;
  }

  consume(input:IReportCategory) {
    this._id = input._id;
    this.description = input.description;
    this.name = input.name;
    this.parent = input.parent;
    this.orderPosition = input.orderPosition;
    this.site = input.belongsTo.value;

  }
}

export class ReportCategory implements ISortable{
  description:string;
  name:string;
  _id:string;
  parent:string;
  reports:Array<Report>;
  childCategories:Array<ReportCategory>;
  orderPosition: number;
  belongsTo:any;

  constructor(input:IReportCategory) {
    this._id = input._id;
    this.name = input.name;
    this.description = input.description;
    this.parent = input.parent;
    this.reports = [];
    this.childCategories = [];
    this.orderPosition = input.orderPosition;
    this.belongsTo = input.belongsTo;
  }

  public AssignChildCategoriesParentId(){
      this.childCategories.forEach(child=>{
         child.parent = this._id;
         if(child){
             child.AssignChildCategoriesParentId();
         }

      });
  }

  public AddReport(input:Report){
    this.reports.push(input);
  }

  public AddReports(inputs:Array<Report>){
    this.reports = this.reports.concat(inputs);
  }

  public AddChildCategory(input:ReportCategory){
    this.childCategories.push(input);
  }

  public SortReports(){
    this.reports.sort((a,b)=>a.displayName.localeCompare(b.displayName));
  }
}