import {UIService} from "@/_services/ui.service";
import {ICharacteristic} from "@/_models/characteristic";
import {Observable} from "rxjs";
import {ApplyOrderPositions, ISortable, Sort} from "@/_models/TaxonomyVersion";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {DragDropHelper} from "@/_services/drag-drop.helper";

export abstract class AbstractEditCharacteristicsComponent extends AbstractDisplayComponent {
    protected tooltipService : UIService;
    public dragDropper: DragDropHelper;
    Characteristics: ICharacteristic[] = [];
    CharacteristicTypeName:string;

    protected constructor() {
        super();
        this.GetDependencies();
    }

    protected GetDependencies(){
        this.dragDropper = this.injector.get(DragDropHelper);
        this.tooltipService = this.injector.get(UIService);
    }

    ApplyOrderPositions(collection:ISortable[]){
        return ApplyOrderPositions(collection);
    }

    protected Init(serverRequest:Observable<ICharacteristic[]>){
        this.LoadContent(serverRequest).then(result=>{
            Sort(result);
            this.Characteristics = result;
         }).catch(error=>{
            this.AfterErrorReceived(error);
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });
    }

    protected LoadContent(serverRequest:Observable<ICharacteristic[]>) : Promise<ICharacteristic[]>{
        this.SetLoadingState();
        return this.ProcessServerRequest(serverRequest);
    }

    Submit(){
        super.Submit();
        this.SetLoadingState();
        ApplyOrderPositions(this.Characteristics);
        this.SubmitRequestToServer().then(()=>{
            super.SetStatusMessage(this.CharacteristicTypeName + " updated");
        }).catch(error=>{
            this.AfterErrorReceived(error, "Error saving characteristic data");
        }).finally(()=>{
            this.TransmissionComplete();
            this.SetFinishedLoadingState();
        })
    }

    protected abstract SubmitRequestToServer() : Promise<void>;

}
