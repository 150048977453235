import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {User, UserCreationDto, UserDto} from '@/_models';
import {AbstractService} from "@/_services/AbstractService";
import {JwtHelperService} from "@auth0/angular-jwt";
import {error} from "util";
import {IMenuLink} from "@/_models/IContent";

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends AbstractService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(protected http: HttpClient,public jwtHelper: JwtHelperService) {
        super(http,"users");
        this.SetCurrentUser();
    }

    private SetCurrentUser(){
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    private GetCurrentUser(){
        if(this.currentUser){
            return this.currentUser;
        }else{
            this.SetCurrentUser();
            return this.currentUser;
        }
    }

    public UpdateUser(user:UserCreationDto, originalName:string){
        return this.http.patch<void>(`${this.apiURL}${originalName}`, user);
    }

    public GetUser(username:string) : Observable<UserDto>{
        return this.http.get<UserDto>(`${this.apiURL}${username}`)
    }


    public GetAllUsers() : Observable<UserDto[]>{
        return this.http.get<UserDto[]>(`${this.apiURL}`)
    }

    public isAuthenticated(): boolean {
        this.GetCurrentUser().subscribe(x=>{
            if(x){
                const token = x.token;
                return !this.jwtHelper.isTokenExpired(token) && this.IsAdminAccount(x);
            }

        });
        // Check whether the token is expired and return
        // true or false
        return true;

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    private IsAdminAccount(user:User){
        return user.userLevel.toLowerCase() =="admin";
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${this.apiURL}authenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page-details refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    DeleteUser(user:UserDto){
        let options = this.httpOptions;
        options["body"] = user;
        return this.http.delete<any>(`${this.apiURL}`,options);
    }

    AddUser(newUser:UserCreationDto){
        return this.http.post<any>(`${this.apiURL}register`, newUser)
    }

    //TODO create registration route?

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}