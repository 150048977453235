import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMenuLink} from "@/_models/IContent";
import {ContentService} from "@/_services";
import {MatDialog} from "@angular/material";
import {DeleteConfirmationComponent} from "@/_components/_forms/delete-confitmation/delete-confirmation.component";
import {PopupInput} from "@/_components/_forms/abstract-popup";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {IsActive} from "@/_helpers/Functions";

@Component({
  selector: 'app-page-arrange-link',
  templateUrl: './page-arrange-item-link.component.html',
  styleUrls: ['./page-arrange-item-link.component.css',"../../_forms/forms.css", "../../dragula.css"]
})
export class PageArrangeItemLinkComponent extends AbstractDisplayComponent implements OnInit {
    @Input() Link:IMenuLink;
    @Input() Parent:IMenuLink;
    @Output() onMessageSet: EventEmitter<string> = new EventEmitter();
    @Output() onErrorSet: EventEmitter<string> = new EventEmitter();
    @Output() onStatusReset: EventEmitter<void> = new EventEmitter();
    @Output() onDelete: EventEmitter<string> = new EventEmitter();
  constructor(
      protected service:ContentService,
    ) {
      super();
  }

  ngOnInit() {
  }

  public SetErrorMessage(error: string) {
      this.onErrorSet.emit(error);
  }

    public SetMessage(message: string) {
      this.onMessageSet.emit(message);
    }

    public ResetStatus() {
      this.onStatusReset.emit();
    }

    public DeleteItem(){
      if (this.Parent){
          this.Parent.children = this.Parent.children.filter(x=>x._id != this.Link._id);
      }
      this.onDelete.emit();
    }

    ProcessDeletion(){
        this.ResetStatus();
        super.Submit();
        this.SetLoadingState();
        this.SubmitToServer(this.Link).then(x=>{
            this.DeleteItem();
        }).catch(error=>{
                this.SetErrorMessage("Error deleting item.");
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });
    }

    SubmitToServer(item:IMenuLink):Promise<void>{
      return this.ProcessServerRequest(this.service.DeleteItem(item));
    }

    IsActive(item){
      return IsActive(item);
    }

}
