import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Report} from "@/_services/reports.service";
import {AbstractDisplayComponent} from "@/_components/AbstractDisplayComponent";

@Component({
  selector: 'app-report-document',
  templateUrl: './report-document.component.html',
  styleUrls: ['./report-document.component.css', "../../_forms/forms.css", "../../dragula.css"]
})
export class ReportDocumentComponent extends AbstractDisplayComponent implements OnInit {
  @Input() Report:Report;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  protected ProcessDeletion() {
    this.onDelete.emit({report:this.Report});
  }
}
