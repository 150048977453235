import {AbstractUploadComponent} from '@/_components/AbstractUploadComponent';
import {IUploader} from '@/_services/UploadService';
import {Input} from '@angular/core';

export abstract class AbstractFormDataUploadComponent extends AbstractUploadComponent {

    public FILE_SELECTED = false;
    public FILE: File;
    public NAME: string;
    @Input() AllowedFileTypes = '*/*';

    constructor(protected service: IUploader<FormData>) {
        super(service);
    }

    protected SetFileSelected() {
        this.FILE_SELECTED = true;
    }

    protected GetResponseValue(response: any) {
        return response ? response : null;
    }

    protected ResetFileSelected() {
        this.FILE_SELECTED = false;
    }

    protected ResetState() {
        super.ResetState();
        this.ResetFileSelected();
    }

    protected SubmitUpload() {
        this.SetFileSelected();
        if (this.FILE == null) {
            return;
        }
        const formDataToUpload = this.CreateFormDataFromFile(this.FILE, this.NAME);
        this.SubmitFile(formDataToUpload);
    }

    private CreateFormDataFromFile(file: File, name: string) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        if (name.length > 0) {
            formData.append('name', name);
        }
        return formData;
    }

    public StartUpload(files: FileList, name: string) {
        this.NAME = name;
        this.FILE = files[0] || null;
        console.log('uploading file');
        this.SubmitUpload();
    }
}
