import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-taxonomy',
  templateUrl: './taxonomy-template.component.html',
  styleUrls: ['./taxonomy-template.component.css']
})
export class TaxonomyTemplateComponent implements OnInit {


  ngOnInit() {

    }

}
