import {IMenuLink} from "@/_models/IContent";
import {Sort} from "@/_models/TaxonomyVersion";

export class SubSiteContent {
    public Name: string;
    private AllLinks: IMenuLink[];
    public HierarchicalLinks: IMenuLink[];
    public DeNestedLinks: IMenuLink[];
    public LinksWithBrokenHierarchy: IMenuLink[];

    constructor(name: string, links: Array<IMenuLink>) {
        this.Name = name;
        this.AllLinks = Sort(links);
        this.LoadLinkHierarchy();
    }

    public LoadLinkHierarchy() {
        this.ResetLinkHierarchy();
        this.CreateLinkHierarchy();
    }

    public RemoveLink(link: IMenuLink) {
        this.AllLinks = this.AllLinks.filter(x => x != link);
        this.LoadLinkHierarchy();
    }

    /*TODO
    if parent id doesnt exist then display it under site root in a separate area
    what happens if uts submitted with a parent id that doesnt exist?
     */

    public UpdateMembership(page: IMenuLink) {
        page.belongsTo.value = this.Name;
    }

    public GetAPIAcceptablePageArray(): IMenuLink[] {
        this.DeNestedLinks = [];
        let position = 0;
        this.HierarchicalLinks.forEach(page => {
            page.orderPosition = position;
            this.UpdateMembership(page);
            this.ProcessChildren(page);
            this.DeNestedLinks.push(page);
            page.children = [];
            page.parentId = [];
            position++;
        });
        return this.DeNestedLinks;
    }

    private ProcessChildren(page: IMenuLink) {
        if (page.children.length > 0) {
            let subPosition = 0;
            page.children.forEach(subpage => {
                this.UpdateMembership(subpage);
                this.SetChildPositionDetails(subpage, subPosition, page._id);
                this.ProcessChildren(subpage);
                subpage.children = [];//resetting this, not sure how the backend handles a populated children array
                this.DeNestedLinks.push(subpage);
                subPosition++;
            });
        }
    }

    private SetChildPositionDetails(page: IMenuLink, position: number, parentId: string) {
        page.orderPosition = position;
        page.parentId = [parentId];
    }

    public AddLink(link: IMenuLink) {
        this.AllLinks.push(link);
    }

    private CreateLinkHierarchy() {
        let links = this.AllLinks;
        links.forEach(page => {
            if (page.children == null) {
                page.children = new Array<IMenuLink>();
            }
            if (page.parentId != null) {
                if (this.IsRootLink(page)) {
                    this.HierarchicalLinks.push(page);
                } else if (this.IsLinkOrphaned(page)) {
                    this.LinksWithBrokenHierarchy.push(page);
                } else {
                    this.AssignLinkToParents(page);
                }
            }
        });
    }

    private ResetLinkHierarchy() {
        this.HierarchicalLinks = [];
        this.DeNestedLinks = [];
        this.LinksWithBrokenHierarchy = [];
    }

    private InstantiateChildPageCollectionIfRequired(link: IMenuLink) {
        if (link.children == null) {
            link.children = new Array<IMenuLink>();
        }
    }

    private IsLinkOrphaned(link: IMenuLink): boolean {
        if (!this.IsRootLink(link)) {
            let parents = this.FindLinkParents(link);
            if (parents.length == 0) {
                return true;
            }
        }
        return false;
    }

    private AssignLinkToParents(link: IMenuLink) {
        let parentPages = this.FindLinkParents(link);
        parentPages.forEach(parent => {
            this.InstantiateChildPageCollectionIfRequired(parent);
            parent.children.push(link);
        });
    }

    private IsRootLink(link: IMenuLink): boolean {
        return link.parentId.length == 0;
    }

    private FindLinkParents(link: IMenuLink): IMenuLink[] {
        return this.AllLinks.filter(x => link.parentId.includes(x._id));
    }
}