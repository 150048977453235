import {Component, OnInit} from '@angular/core';
import {UIService} from "@/_services/ui.service";
import {AbstractEditEnvironmentsComponent} from "@/_components/characteristic-management/environment-editing/abstract-edit-environments.component";
import {DragulaService} from "ng2-dragula";

@Component({
  selector: 'app-environment-metabolisms',
    templateUrl: './environments-edit.html',
    styleUrls: ['../../dragula.css', '../../_forms/forms.css']
})
export class EnvironmentsEditMetabolismsComponent extends AbstractEditEnvironmentsComponent implements OnInit{
    EnvironmentTypeName="metabolisms";

    constructor(
        protected dragula:DragulaService,
        protected tooltipService : UIService
    ) {
        super();
    }

    ngOnInit() {
        this.Init(this.tooltipService.GetMetabolismEnvironments());
    }

    protected SubmitRequestToServer(): Promise<void> {
        return super.ProcessServerRequest(this.tooltipService.UpdateMetabolismEnvironments(this.Environments));
    }
}
