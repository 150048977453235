import {Component} from '@angular/core';
import {Validators} from "@angular/forms";
import {ConfigService} from "@/_services";
import {AbstractSiteSettingsComponent} from "@/_components/site-settings/AbstractSiteSettingsComponent";

@Component({
  selector: 'app-taxonomy-settings',
  templateUrl: './taxonomy-settings.component.html',
  styleUrls: ['./taxonomy-settings.component.css']
})
export class TaxonomySettingsComponent extends AbstractSiteSettingsComponent {
    TaxonomyValidators = Validators.required;

  constructor(
      protected configService:ConfigService) {
      super(configService);
  }
}



