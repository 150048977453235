import { Component, OnInit } from '@angular/core';
import {AbstractDisplayComponent} from '@/_components/AbstractDisplayComponent';

@Component({
  selector: 'app-global-abundance-management',
  templateUrl: './global-abundance-management.component.html',
  styleUrls: []
})
export class GlobalAbundanceManagementComponent extends AbstractDisplayComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
  }

}
