import {Component, OnInit} from '@angular/core';
import {MicrobeService} from "@/_services";
import {AbstractDisplayComponent} from "../../../AbstractDisplayComponent";

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class MatchingComponent extends AbstractDisplayComponent implements OnInit {
  UnmatchedMicrobes: Array<IBasicMicrobe>;
  AllMicrobes: Array<IBasicMicrobe>;

  constructor(
      protected microbeService: MicrobeService
  ) {
    super();
  }

  ngOnInit() {
    this.LoadContent();
  }

  private LoadContent(){
    this.SetLoadingState();
    Promise.all([
      this.GetPendingMicrobes(),
    ]).then(([pending])=>{
      this.UnmatchedMicrobes = pending;
    }).catch(error=>{
      this.AfterErrorReceived(error, "Error getting microbes.");
    }).finally(()=>{
      this.SetFinishedLoadingState();
    });
  }

  private GetPendingMicrobes() {
    return this.ProcessServerRequest(this.microbeService.GetAllUnMatched());
  }

  public DeleteMicrobe(id:string){
    this.ProcessServerRequest(this.microbeService.DeleteMicrobe(id)).then(result=>{
      this.RemoveMicrobeFromPendingList(id);
      this.SetStatusMessage("Pending microbe deleted successfully.")
    }).catch(error=>{
      this.AfterErrorReceived(error, "Error deleting pending microbe with id: " + id);
    });
  }

  public SubmitPendingMicrobeOverrideMatch(input: any) {
      this.microbeService.SubmitPendingMicrobeOverride(input.pendingMicrobeID, input.oldMicrobeName).subscribe(x => {
        this.RemoveMicrobeFromPendingList(input.pendingMicrobeID);
        this.SetStatusMessage("Taxonomy microbe updated with details of pending microbe successfully.");
      }, error => {
        this.AfterErrorReceived(error, "Error updating microbe, perhaps that microbe name doesn't exist");
      });
  }

  private RemoveMicrobeFromPendingList(id:string){
    this.UnmatchedMicrobes = this.UnmatchedMicrobes.filter(x=>x._id != id);
  }
}

//TODO event emitter to make a dictionary/list of values to send to the server

export interface IBasicMicrobe{
  _id:string;
  name:string;
  inFieldGuide:boolean;
}
