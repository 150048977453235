import {Component, OnInit} from '@angular/core';
import {TextSnippet} from "@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component";
import {SnippetService} from "@/_services/snippet.service";
import {IDictionary} from "@/_models/microbe";
import {AbstractDisplayComponent} from "../AbstractDisplayComponent";
import {AbstractEditorComponent} from "@/_components/AbstractEditorComponent";

@Component({
  selector: 'app-content-snippet-management',
  templateUrl: './content-snippet-management.component.html',
  styleUrls: ['./content-snippet-management.component.css']
})
export class ContentSnippetManagementComponent extends AbstractEditorComponent implements OnInit {
    public CitedTextSnippets: IDictionary<TextSnippet>;
    public TextSnippets: IDictionary<TextSnippet>;

    constructor(
        protected service: SnippetService) {
        super();
    }


    ngOnInit() {
        this.InitialiseEditorRequirements().then(()=> {
            this.GetCitedTextSnippets().then(result => {
                this.CitedTextSnippets = result;
            });

            this.GetTextSnippets().then(result => {
                this.TextSnippets = result;
            });
        });
    }

    GetTextSnippets(): Promise<IDictionary<TextSnippet>> {
        return new Promise<any>((resolve, reject) => {
            let result = new Dictionary<TextSnippet>();
            this.service.GetTextSnippets().subscribe(res => {
                let pTypes = Object.keys(res);
                pTypes.forEach(p => {
                    result[p] = TextSnippet.CreateFrom(res[p]);
                });
                resolve(result);
            }),
                error => {
                    reject(error);
                }
        });
    }

    GetCitedTextSnippets(): Promise<IDictionary<TextSnippet>> {
        return new Promise<any>((resolve, reject) => {
            let result = new Dictionary<TextSnippet>();
            this.service.GetCitedSnippets().subscribe(res => {
                let pTypes = Object.keys(res);
                pTypes.forEach(p => {
                    result[p] = TextSnippet.CreateFrom(res[p]);
                });
                resolve(result);
            }),
                error => {
                    reject(error);
                }
        });
    }

    public GetSnippet(name:string, cited:boolean){
        let found = this.CitedTextSnippets[name] || this.TextSnippets[name];
        if(found == undefined){
            let defaultValue = new TextSnippet("",name, false,null);
            if(cited){
                this.CitedTextSnippets[name] = defaultValue;
            }else{
                this.TextSnippets[name] = defaultValue;
            }
            return defaultValue;
        }
        return found;
    }
}

export class Dictionary<T> implements IDictionary<T>{
    [key: string]: T;
    constructor(){
    }
}
