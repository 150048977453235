import {Component, OnInit} from '@angular/core';
import {IReport, IReportCategory, Report, ReportCategory, ReportsService} from "@/_services/reports.service";
import {ApplyOrderPositions, Sort} from "@/_models/TaxonomyVersion";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {DragDropHelper} from "@/_services/drag-drop.helper";
import {ActivatedRoute} from "@angular/router";
import {ContentService} from "@/_services";
import {Dictionary} from "@/_components/content-snippet-management/content-snippet-management.component";

@Component({
    selector: 'app-report-management',
    templateUrl: './report-management.component.html',
    styleUrls: ['./report-management.component.css', "../../_forms/forms.css", "../../dragula.css"]
})
export class ReportManagementComponent extends AbstractDisplayComponent implements OnInit {
    SubSiteNames: Array<string>;
    ReportCategories: ReportCategory[];
    CategoriesBySite:Dictionary<ReportCategory[]>;
    UnNestedReportCategories: ReportCategory[];

    constructor(
        protected service: ReportsService,
        public dragDropper: DragDropHelper,
        public contentService: ContentService
    ) {
        super();
    }

    //TODO remove from dictionary on delete, etc

    ngOnInit() {
        this.SetLoadingState();
        this.LoadContent().finally(() => {
            this.SetFinishedLoadingState();
        });
    }

    GetCategoriesForSiteName(siteName:string){
        return this.CategoriesBySite[siteName] || new Array<ReportCategory>();
    }

    DeleteCategory(subSiteName:string, $event:ReportCategory){
        this.Submit();
        this.SetLoadingState();
        this.ProcessServerRequest(this.service.DeleteReportCategory($event._id)).then(result=>{
            this.ReportCategories = this.ReportCategories.filter(x=>x != $event);
            this.CategoriesBySite[subSiteName] = this.CategoriesBySite[subSiteName].filter(x=>x != $event);
            this.SetStatusMessage("Category deleted successfully.");
        }).catch(error=>{
            this.AfterErrorReceived(error,"Error deleting category.");
        }).finally(()=>{
            this.SetFinishedLoadingState();
        })
    }

    protected LoadSubSiteNames():Promise<string[]>{
        return this.ProcessServerRequest<string[]>(this.contentService.GetSubSiteNames());
    }

  SubmitPositioningUpdate() {
    super.SetLoadingState();
    this.AssignCategoryParentIds();
    this.AssignSubSiteMembership();
    this.ApplySubSiteCategoriesOrderPositions();
    super.SetStatusMessage("New category order submitted.");
    super.Submit();
    this.SubmitReorderingToServer()
        .then(() => {
          this.SetStatusMessage("New category order saved.");
        })
        .catch(error => {
          this.AfterErrorReceived(error, "Error reordering categories.");
        }).finally(()=>{
          this.SetFinishedLoadingState();
        });
    }

    private AssignCategoryParentIds() {
        let subSiteNames = Object.keys(this.CategoriesBySite);
        subSiteNames.forEach(name => {
            this.CategoriesBySite[name].forEach(category=>{
                category.parent = null;
                category.AssignChildCategoriesParentId()
            });
        })
    }

    private ApplySubSiteCategoriesOrderPositions(){
        let subSiteNames = Object.keys(this.CategoriesBySite);
        subSiteNames.forEach(name => {
            ApplyOrderPositions(this.CategoriesBySite[name]);
        })
    }

    private AssignSubSiteMembership() {
        let subSiteNames = Object.keys(this.CategoriesBySite);
        subSiteNames.forEach(name => {
            this.CategoriesBySite[name].forEach(category=>{
                category.belongsTo.value = name;
            })
        })
    }

    SubmitReorderingToServer(): Promise<void> {
      return this.ProcessServerRequest(this.service.ReorderCategories(this.UnNestedReportCategories));
    }

    protected GetReportCategories(): Promise<Array<IReportCategory>> {
        return this.ProcessServerRequest(this.service.GetReportCategories());
    }

    protected GetReports(): Promise<Array<IReport>> {
        return this.ProcessServerRequest(this.service.GetReports());
    }

    protected AddReportsToCategories(categories: Array<ReportCategory>, reports: Array<Report>) {
        categories.forEach(cat => {
            cat.AddReports(reports.filter(rep => rep.category == cat._id));
            cat.SortReports();
        });
        return categories;
    }

    protected AddCategoriesToCategories(categories: Array<ReportCategory>): Array<ReportCategory> {
        categories.forEach(cat => {
            if (cat.parent != null) {
                let parent = categories.find(potentialParent => potentialParent._id == cat.parent);
                if (parent) {
                    parent.AddChildCategory(cat);
                }
            }
        });
        return categories.filter(cat => cat.parent == null);
    }

    private InstantiateCategories(categories: IReportCategory[]): Array<ReportCategory> {
        let results = new Array<ReportCategory>();
        categories.forEach(x => results.push(new ReportCategory(x)));
        return results;
    }

    private InstantiateReports(reports: IReport[]): Array<Report> {
        let results = new Array<Report>();
        reports.forEach(x => results.push(new Report(x)));
        return results;
    }

    private RemoveSubSitesWithNoReportsPage(names:string[]){
        names = names.filter(x=>!["guide","global"].includes(x))
        return names;
    }

    //TODO refactor and tidy up
    protected LoadContent() : Promise<void> {
        return new Promise<void>((resolve,reject)=>{
            this.LoadSubSiteNames().then(result=>{
                this.SubSiteNames = this.RemoveSubSitesWithNoReportsPage(result);
                this.SubSiteNames.push("all");
                this.GetReportCategories().then(resultCategories => {
                    this.CategoriesBySite = new Dictionary<ReportCategory[]>();
                      this.SubSiteNames.forEach(name=>{
                        this.CategoriesBySite[name] = new Array<ReportCategory>();
                    });
                    this.GetReports().then(resultReports => {
                        let categories = this.InstantiateCategories(resultCategories);
                        let reports = this.InstantiateReports(resultReports);
                        let categoriesWithReports = this.AddReportsToCategories(categories, reports);
                        Sort(categoriesWithReports);
                        this.UnNestedReportCategories = categoriesWithReports;
                        this.ReportCategories = this.AddCategoriesToCategories(categoriesWithReports);

                        this.SubSiteNames.forEach(subSite=>{
                            this.CategoriesBySite[subSite] = this.ReportCategories.filter(cat=>cat.belongsTo.value == subSite);
                        })
                        resolve();
                    }).catch(error => {
                        console.log(error);
                        this.AfterErrorReceived(error, "Error retrieving report information.")
                        reject();
                    })
                }).catch(error => {
                    this.AfterErrorReceived(error, "Error retrieving report categories.")
                    reject();
                })
            }).catch(error=>{
                this.AfterErrorReceived(error, "Error retrieving sub site names.")
                reject();
            })
        })


    }
}
