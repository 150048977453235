import {Component, OnInit, ViewChild} from '@angular/core';
import {TaxonomyVersionPair} from "@/_models/TaxonomyVersion";
import {TaxonomyVersionService} from "@/_services/taxonomyVersion.service";
import {UploadService} from "@/_services/UploadService";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {TaxonomyVersionListComponent} from "@/_components/taxonomy-management/taxonomy-version-list/taxonomy-version-list.component";

@Component({
  selector: 'app-taxonomy-version-management',
  templateUrl: './taxonomy-version-management.component.html',
  styleUrls: ['./taxonomy-version-management.component.css'],
    providers: [{provide: UploadService, useClass: TaxonomyVersionService}]
})
export class TaxonomyVersionManagementComponent extends AbstractDisplayComponent implements OnInit {
    @ViewChild(TaxonomyVersionListComponent,{static: false}) taxonomyListChild: TaxonomyVersionListComponent ;
    SelectedVersion:TaxonomyVersionPair;

    constructor() {
        super();
    }

  ngOnInit() {
  }

    SetSelectedVersion(event){
        this.SelectedVersion = event;
    }

    ReloadExistingVersions(){
        this.taxonomyListChild.ReloadContent();
    }

}
