import {Component, Input, OnInit} from '@angular/core';

import {UploadService} from '@/_services/UploadService';
import {AbundanceService} from '@/_services/abundance.service';
import {AbstractFormDataUploadComponent} from '@/_components/common/upload/AbstractFormDataUploadComponent';

@Component({
  selector: 'app-abundance-update',
    templateUrl: '../common/upload/upload.component.html',
    styleUrls: ['../common/upload/upload.component.css'],
  providers: [{provide: UploadService, useClass: AbundanceService}]
})
export class DanishAbundanceUpdateComponent extends AbstractFormDataUploadComponent implements OnInit {
@Input() key;
@Input() title;
@Input() AllowedFileTypes = '.csv';

    constructor(protected service: UploadService) {
        super(service);
    }

    ngOnInit() {
        this.fileKey = this.key;
        this.Intro = '<h3>' + this.title + '</h3>';
    }
}
