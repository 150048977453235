import {AuthenticationService} from "@/_services";
import {Observable} from "rxjs";
import {Injector} from "@angular/core";
import {AbstractComponentBase} from "@/_components/AbstractComponentBase";
import {ReceivedError} from "@/_helpers";
import {PopupInput} from "@/_components/_forms/abstract-popup";
import {DeleteConfirmationComponent} from "@/_components/_forms/delete-confitmation/delete-confirmation.component";
import {MatDialog} from "@angular/material";


export abstract class AbstractDisplayComponent extends AbstractComponentBase{
    protected authenticationService:AuthenticationService;
    protected injector:Injector;
    protected deleteConfirmDialog : MatDialog
    LOADING = true;
    ERROR_MESSAGES:Array<string> = new Array<string>();
    STATUS_MESSAGE:string = null;
    SUBMITTED = false;
    RESPONSE_RECEIVED = false;

    protected constructor(){
        super();
    }

    protected GetDependencies(){
        this.authenticationService = this.injector.get(AuthenticationService);
        this.deleteConfirmDialog = this.injector.get(MatDialog);
    }

    SetFinishedLoadingState(){
        this.LOADING = false;
    }

    SetLoadingState(){
        this.LOADING = true;
    }

    protected AfterErrorReceived(error: ReceivedError, defaultError:string = null){
        if(defaultError){
            this.AddErrorMessage(defaultError);
            return;
        }
        this.AddErrorMessage(error.Message);
    }

    AddErrorMessage(error:string){
        this.ERROR_MESSAGES.push(error);
    }

    Submit(){
        this.ResetState();
        this.SUBMITTED = true;
    }
    protected ResetState(){
        this.ResetErrors();
        this.ResetStatusMessage();
        this.ResetSubmittedStatus();
        this.ResetResponseReceivedStatus();
    }

    ResetStatusMessage(){
        this.STATUS_MESSAGE = null;
    }

    ResetSubmittedStatus(){
        this.SUBMITTED = false;
    }

    HasResponseBeenReceived(){
        return this.RESPONSE_RECEIVED;
    }

    SetStatusMessage(msg:string){
        this.STATUS_MESSAGE = msg;
    }

    GetMessage(){
        return this.STATUS_MESSAGE;
    }

    SetResponseReceivedStatus(){
        this.RESPONSE_RECEIVED = true;
    }

    ResetResponseReceivedStatus(){
        this.RESPONSE_RECEIVED = false;
    }



    GetErrorMessages(){
        return this.ERROR_MESSAGES || [];
    }

    IsSubmitted(){
        return this.SUBMITTED;
    }

    ResetErrors(){
        this.ERROR_MESSAGES = new Array<string>();
    }

    protected ProcessServerRequest<T>(observable :Observable<T>) : Promise<T>{
        return new Promise<T>((resolve, reject) =>
            observable.subscribe(result => {
                resolve(result);
            }, error => {
                reject(error);
            }));
    }

    PreInit(){
        this.ResetState();
    }

    IsLoading(){
        return this.LOADING;
    }

    TransmissionComplete(){

    }

    protected ProcessDeletion(){
    }

    OpenDeleteDialog(action) {
        let popupInput = false;
        let input = new PopupInput(action, popupInput);
        const dialogRef = this.deleteConfirmDialog.open(DeleteConfirmationComponent, {
            data: input
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.event!="Cancel") {
                result.data = result.data as boolean;
                let output = result.data as boolean;
                if(output){
                    if (result.event == 'delete') {
                        this.ProcessDeletion()
                    } else {

                    }
                }
            }
        });
    }


}