import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserCreationDto} from "@/_models";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {AuthenticationService} from "@/_services";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['../register-user/register-user.component.css'],
    encapsulation:ViewEncapsulation.None
})
export class EditUserComponent extends AbstractDisplayComponent implements OnInit {
  OriginalUserName:string;
  User:UserCreationDto;
  USER_TYPE="User";
  ADMIN_TYPE="Admin";
  PasswordsMatch = false;
  UserLevelEnabled = false;
  ButtonText = "Update user";


  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected userService: AuthenticationService
              ) {
    super();
    super.SetFinishedLoadingState();
  }

  ngOnInit() {
    this.LoadContent();
  }

  private GetUserNameFromURL():string{
    return this.route.snapshot.paramMap.get('username');
  }

  private LoadContent() {
    this.SetLoadingState();
    let userName = this.GetUserNameFromURL();
    this.OriginalUserName = userName;
    this.ProcessServerRequest(this.userService.GetUser(userName)).then(resultUser=>{
      this.User = new UserCreationDto();
      this.User.userLevel = resultUser.userLevel;
      this.User.username = resultUser.username;
      this.User.firstName = resultUser.firstName;
      this.User.lastname = resultUser.lastname;
    }).catch(error=>{
      this.AfterErrorReceived(error);
    }).finally(()=>{
      this.SetFinishedLoadingState();
    });
  }

  handleSelectedUserType(type) {
    if (type == this.ADMIN_TYPE) {
      this.User.userLevel = this.ADMIN_TYPE;
    } else if (type == this.USER_TYPE) {
      this.User.userLevel = this.USER_TYPE;
    }
  }

  ComparePasswordFields(){
    this.ResetErrors();
    if(this.User.repeatPassword != this.User.password){
      this.PasswordsMatch = false;
      super.AddErrorMessage("Password fields must match")
    }
    else{
      super.SetStatusMessage("");
      this.PasswordsMatch = true;
    }
  }

  SaveUser(){
    super.Submit();
    super.SetLoadingState();
    this.ProcessServerRequest(this.authenticationService.UpdateUser(this.User, this.OriginalUserName)).then(result=>{
      super.SetStatusMessage("User updated successfully");
    }).catch(error=>{
      super.AfterErrorReceived(error);
    }).finally(()=>{
      super.SetFinishedLoadingState();
    });
  }

}
