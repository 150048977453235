import {Component, Input, OnInit} from '@angular/core';
import {TaxonomyService} from "@/_services";
import {UploadService} from "@/_services/UploadService";
import {AbstractFormDataUploadComponent} from "@/_components/common/upload/AbstractFormDataUploadComponent";

@Component({
  selector: 'app-update-database-taxonomy',
    templateUrl: '../common/upload/upload.component.html',
    styleUrls: ['../common/upload/upload.component.css'],
  providers: [{provide: UploadService, useClass: TaxonomyService}]
})
export class UpdateDatabaseTaxonomyComponent extends AbstractFormDataUploadComponent implements OnInit {
    @Input() AllowedFileTypes = ".fa"
    Intro = "<h1>Update the taxonomy</h1>\n" +
        "<p>Upload a new taxonomy file below and the taxonomy will be automatically updated.</p>\n" +
        "<p>Note: taxonomy file must be in <strong>.FA</strong> format.</p>\n";

  constructor(protected service: UploadService) {
    super(service);
  }

  ngOnInit() {
  }

  protected GetResponseValue(response: any) {
    return response ? response.message : null;
  }
}