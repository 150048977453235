import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-taxonomy-menu',
  templateUrl: './taxonomy-menu.component.html',
  styleUrls: ['./taxonomy-menu.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class TaxonomyMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
