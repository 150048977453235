import {Component, OnInit} from '@angular/core';
import {GlobalContributorsSnippetManagementComponent} from "@/_components/text-snippet-management/global-contributors-snippet-management/global-contributors-snippet-management.component";

@Component({
  selector: 'app-ad-contributors',
  templateUrl: './global-contributors-snippet-management.component.html',
  styleUrls: ['./global-contributors-snippet-management.component.css']
})
export class AdContributorsComponent extends GlobalContributorsSnippetManagementComponent implements OnInit {
    ContributorCategory = "ad";

    constructor() {
        super();
    }


    DeleteSnippetFromServer($event){
        this.SetLoadingState();
        this.ProcessServerRequest(this.snippetService.DeleteADContributorSnippet($event)).then(()=>{
            super.SetStatusMessage("Contributors deleted successfully");
        }).catch(error=>{
            super.AfterErrorReceived(error, "Error deleting snippet.");
        }).finally(()=>{
            super.SetFinishedLoadingState();
        });
    }
}
