import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from "rxjs";
import { IMicrobeDetails } from "@/_models/microbe";
import { UploadService } from "@/_services/UploadService";
import {IBasicMicrobe} from "@/_components/taxonomy-management/matching/matching/matching.component";
import {IMicrobeUpdateResponse} from "@/_components/AbstractUploadComponent";


@Injectable({ providedIn: 'root' })
export class MicrobeService extends UploadService{
    constructor(protected http: HttpClient) {
        super(http,"microbes");
    }

    public UploadFile(values:FormData){
        return this.http.post<IMicrobeUpdateResponse>(`${this.apiURL}`, values,{reportProgress: true, observe: 'events'});
    }

    public GetAllUnMatched(){
        return this.http.get<IBasicMicrobe[]>(`${this.apiURL}pending`);
    }

    public GetAll(){
        return this.http.get<IBasicMicrobe[]>(`${this.apiURL}all`);
    }

    public SubmitPendingMicrobeOverride(pendingMicrobeID:string, oldMicrobeName:string){
        return this.http.post<any>(`${this.apiURL}pending`, { "pendingMicrobeId":pendingMicrobeID, "taxonomyMicrobeName":oldMicrobeName });
    }

    public DeleteMicrobe(ID:string){
        return this.http.delete<any>(`${this.apiURL}`+ID);
    }
}

export interface ISearchResponse {
    total_count: number;
    incomplete_results: boolean;
    items: string[];
}