import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {
  @Input() Messages:Array<string>;
  constructor() { }

  ErrorsExist(){
    if (this.Messages && this.Messages.length>0){
      return true;
    }
  }
}
