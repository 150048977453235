import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";
import {AbstractService} from "@/_services/AbstractService";
import {ISetting} from "@/_models/ISetting";

@Injectable({ providedIn: 'root' })
export class ConfigService extends AbstractService{
    constructor(protected http: HttpClient) {
        super(http, "site");
    }

    Set(title:string, value:string) :Observable<void>{
        return this.http.post<any>(`${this.apiURL}`,{ title, value });
    }

    Get(title:string) :Observable<ISetting>{
        return this.http.get<ISetting>(`${this.apiURL}secure/${title}`);
    }
}