import {AbstractDisplayComponent} from "@/_components/AbstractDisplayComponent";
import {Input} from "@angular/core";
import {ISimplePage, SimplePage} from "@/_models/SimplePage";
import { ContentService} from "@/_services";
import {FileManagementService, FileReference} from "@/_services/file-management.service";

export abstract class AbstractEditorComponent extends AbstractDisplayComponent{
    protected contentService: ContentService;
    protected FileManagerService:FileManagementService
    @Input() AllPages: Array<ISimplePage>;
    @Input() AppLinksPages: Array<SimplePage>;
    @Input() FileLinks: Array<FileReference>;
    @Input() SubSiteNames: Array<string>;

    protected constructor() {
        super();
    }

    protected GetDependencies(){
        this.contentService = this.injector.get(ContentService);
        this.FileManagerService = this.injector.get(FileManagementService);
        super.GetDependencies();
    }

    protected InitialiseEditorRequirements(){
        return Promise.all([this.GetSubSiteNames(),this.GetAllPageLinks(),this.InitialiseApplicationLinks(),this.GetFileLinks()])
    }

    protected GetSubSiteNames():Promise<Array<string>>{
        return new Promise<Array<string>>(resolve => {
            if(!this.SubSiteNames){
                this.ProcessServerRequest<Array<string>>(this.contentService.GetSubSiteNames()).then(result=>{
                    this.SubSiteNames = result;
                    resolve(this.SubSiteNames);
                });
            }else{
                resolve(this.SubSiteNames);
            }
            //TODO error catching etc
        })
    }

    protected GetFileLinks():Promise<Array<FileReference>>{
        return new Promise<Array<FileReference>>(resolve => {
            if(!this.FileLinks){
                this.ProcessServerRequest<Array<FileReference>>(this.FileManagerService.GetAllFiles()).then(result=>{
                    this.FileLinks = result;
                    resolve(this.FileLinks);
                });
            }else{
                resolve(this.FileLinks);
            }
            //TODO error catching etc
        })
    }

    protected GetAllPageLinks():Promise<Array<ISimplePage>>{

        return new Promise<Array<ISimplePage>>(resolve => {
            if(!this.AllPages){
                this.ProcessServerRequest<Array<ISimplePage>>(this.contentService.GetSimplePages()).then(result=>{
                    this.AllPages = result;
                    resolve(this.AllPages);
                });
            }else{
                resolve(this.AllPages);
            }
            //TODO error catching etc
        })
    }

    protected InitialiseApplicationLinks():Promise<Array<SimplePage>>{
        return new Promise<Array<SimplePage>>(resolve=>{
            if(!this.AppLinksPages){
                this.AppLinksPages = new Array<SimplePage>();
                this.AppLinksPages.push(new SimplePage("/guide/search","Field guide search","guide"));
                this.AppLinksPages.push(new SimplePage("/guide/blast","BLAST","guide"));
                this.AppLinksPages.push(new SimplePage("/guide/taxonomy","Browse taxonomy","guide"));
                this.AppLinksPages.push(new SimplePage("/guide/global_abundance_as","Global abundance AS","guide"));
                this.AppLinksPages.push(new SimplePage("/guide/global_abundance_ad","Global abundance AD","guide"));
                this.AppLinksPages.push(new SimplePage("/guide/antibiotic-resistance","Antibiotic resistance","guide"));
                this.AppLinksPages.push(new SimplePage("/guide/contact","Contact form","guide"));

                this.AppLinksPages.push(new SimplePage("/global/wwtps-map","WWTPS contributors map","global"));
                this.AppLinksPages.push(new SimplePage("/global/ad-map","AD contributors map","global"));
                this.AppLinksPages.push(new SimplePage("/global/contact","Contact form","global"));

                this.AppLinksPages.push(new SimplePage("/danish/contact","Contact form","danish"));
                this.AppLinksPages.push(new SimplePage("/danish/reports","Reports","danish"));

                this.AppLinksPages.push(new SimplePage("/sweden/contact","Contact form","swedish"));
                this.AppLinksPages.push(new SimplePage("/sweden/reports","Reports","swedish"));
            }
            resolve(this.AppLinksPages);
        });

    }

}
