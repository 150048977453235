import {Component} from '@angular/core';
import {Validators} from "@angular/forms";
import {ConfigService} from "@/_services";
import {AbstractSiteSettingsComponent} from "@/_components/site-settings/AbstractSiteSettingsComponent";

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent extends AbstractSiteSettingsComponent {
    EmailValidators = [Validators.required,Validators.email];

  constructor(
      protected configService:ConfigService) {
      super(configService);
  }
}



