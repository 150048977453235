import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@/_services';
import {Location} from "@angular/common";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && this.authenticationService.isAuthenticated()) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page-details with the return url

        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}