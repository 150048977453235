import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.css'],
    encapsulation:ViewEncapsulation.None
})
export class SiteSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }


}
