import {Component, OnInit} from '@angular/core';
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";

@Component({
  selector: 'app-abundance',
  templateUrl: './abundance.component.html',
  styleUrls: ['../../../app.component.css']
})
export class AbundanceComponent extends AbstractDisplayComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }
}
