import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UploadService} from "@/_services/UploadService";
import {ImageService} from "@/_services/image.service";
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {GlobalContributorSnippet,} from "@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component";
import {SnippetService} from "@/_services/snippet.service";
import {MatDialog} from "@angular/material";

@Component({
    selector: 'app-global-contributor',
    templateUrl: './global-contributor.component.html',
    styleUrls: ['../../app.component.css'],
    providers: [{provide: UploadService, useClass: ImageService}],
    encapsulation: ViewEncapsulation.None
})
export class GlobalContributorComponent extends AbstractContentEditor implements OnInit {
    @Input() public Snippet: GlobalContributorSnippet;
    @Input() public Category: string;
    ReferencesEnabled = false;
    EditorHeight = 200;
    expandPanel: boolean = false;
    @Output() DeleteSnippetFromLocalCollection: EventEmitter<any> = new EventEmitter();
    @Output() SetParentError: EventEmitter<string> = new EventEmitter();
    @Output() SetParentStatus: EventEmitter<string> = new EventEmitter();

    constructor(
        protected service: SnippetService,
        protected deleteConfirmDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }


    GetSaveButtonText() {
        if (this.Snippet.existing) {
            return this.ButtonText;
        }
        return "Save new contributors";
    }

    protected ProcessDeletion() {
        if (this.Snippet.existing) {
            this.ResetState();
            this.SetLoadingState();
            this.SUBMITTED = true;
            this.SetParentStatus.emit("Delete request submitted.")
            this.SendDeleteRequestToServer().then(() => {
                this.SetParentStatus.emit("Contributor deleted successfully.");
                this.DeleteSnippetFromLocalCollection.emit(this.Snippet);
            }).catch(error => {
                this.AfterErrorReceived(error, "Error deleting contributors");
            }).finally(() => {
                this.SetFinishedLoadingState();
            });
        } else {
            this.DeleteSnippetFromLocalCollection.emit(this.Snippet);
        }
    }

    protected SendDeleteRequestToServer(): Promise<void> {
        return this.ProcessServerRequest(this.service.DeleteSnippet(this.Snippet));
    }

    protected SubmitToServer(): Promise<void> {
        if (this.Snippet.existing) {
            return this.ProcessServerRequest(this.service.UpdateGlobalContributor(this.Snippet, this.Category));
        } else {
            return this.ProcessServerRequest(this.service.AddGlobalContributor(this.Snippet, this.Category)).then(() => {
                this.Snippet.existing = true;
            });
        }
    }

    protected InitialiseContentForDisplayInEditor(): Promise<void> {
        //There's no initialising to do, but the calling method expects a promise
        return new Promise((resolve, reject) => {
            resolve();
        });
    }

}