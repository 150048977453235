import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from "rxjs";
import {AbstractService} from "@/_services/AbstractService";
import {ITaxonomyVersionReceive, ITaxonomyVersionSubmit} from "@/_models/TaxonomyVersion";
import {IUploader} from "@/_services/UploadService";
import {ConvertTaxonomyVersion} from "@/_helpers/Functions";

@Injectable({ providedIn: 'root' })
export class TaxonomyVersionService extends AbstractService implements IUploader<ITaxonomyVersionSubmit>{
    constructor(protected http: HttpClient) {
        super(http, "taxonomy/versions");
    }

    public AddVersion(version: ITaxonomyVersionSubmit) :Observable<HttpEvent<any>>{
        let formData = ConvertTaxonomyVersion(version);
        return this.http.post<any>(`${this.apiURL}add`, formData ,{reportProgress: true, observe: 'events'});
    }

    public UpdateVersion(version: ITaxonomyVersionSubmit){
        let formData = ConvertTaxonomyVersion(version);
        return this.http.post<any>(`${this.apiURL}update`, formData,{reportProgress: true, observe: 'events'});
    }

    public UpdateVersionOrder(versions: Array<ITaxonomyVersionSubmit>) :Observable<any>{
        return this.http.post<any>(`${this.apiURL}update_order`, versions );
    }

    public GetAllVersions(){
        return this.http.get<ITaxonomyVersionReceive[]>(`${this.apiURL}`);
    }

    Upload(object: ITaxonomyVersionSubmit) {
        return this.AddVersion(object);
    }
}