import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users-template.component.html',
  styleUrls: ['./users-template.component.css'],
    encapsulation:ViewEncapsulation.None
})
export class UsersTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
