import { HttpClient } from '@angular/common/http';
import {UploadService} from "@/_services/UploadService";

export class TaxonomyService extends UploadService{
    constructor(protected http: HttpClient) {
        super(http,"taxonomy");
    }

    public UploadFile(values:FormData){
        return this.http.post(`${this.apiURL}`, values,{reportProgress: true, observe: 'events'});
    }
}