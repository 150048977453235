import { HttpClient } from '@angular/common/http';
import {UploadService} from "@/_services/UploadService";

export class ReparesService extends UploadService{
    constructor(protected http: HttpClient) {
        super(http,"microbes");
    }

    public UploadFile(values:FormData){
        return this.http.post(`${this.apiURL}repares`, values,{reportProgress: true, observe: 'events'});
    }
}