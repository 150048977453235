import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserDto} from "@/_models";
import {AbstractDisplayComponent} from "@/_components/AbstractDisplayComponent";

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.css']
})
export class UserListItemComponent extends AbstractDisplayComponent implements OnInit {
  @Input() User:UserDto;
  @Output() onDelete: EventEmitter<UserDto> = new EventEmitter<UserDto>();
  constructor() {
    super();
  }

  ngOnInit() {
  }

  protected ProcessDeletion() {
    this.onDelete.emit(this.User);
  }
}
