import {Component, Input, OnInit} from '@angular/core';
import {IReceivedFile, SubmitFile} from "@/_models/TaxonomyVersion";

@Component({
  selector: 'app-archive-file',
  templateUrl: './archive-file.component.html',
  styleUrls: ['./archive-file.component.css']
})
export class ArchiveFileComponent implements OnInit {
  @Input() ReceivedFile: IReceivedFile;
  @Input() SubmitFile: SubmitFile;

  constructor() { }

  ngOnInit() {

  }

  AddOrUpdateFile(event){
    this.SubmitFile.file = event.target.files[0];
  }

}

