import {Component, OnInit} from '@angular/core';
import {ContentService} from "@/_services";
import {ActivatedRoute} from "@angular/router";
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {IContentPage, IValueString, IValueWithReferences} from "@/_models/IContent";
import * as language from "../../../Language/language.json";

@Component({
  selector: 'app-content-add',
  templateUrl: '../content-editor-template.html',
  styleUrls: ['../content-editing.css']
})
export class AddContentComponent extends AbstractContentEditor implements OnInit {
  Title="Add new page";
  ButtonText = language.GENERIC_SAVE_BUTTON_TEXT;
  ChangesSubmittedText = language.GENERIC_SUBMITTED;
  public Page:IContentPage;
  SuccessRedirectURLBase = '/content/page/edit/';

  constructor(
      protected contentService:ContentService,
      protected route: ActivatedRoute
  ) {
    super();
  }

  CreatePage(){
    this.Page = <IContentPage>{
      parentId:this.ParentID ? [this.ParentID] : [],
      belongsTo:<IValueString>{value:this.SiteName},
      contents:new Array<IValueWithReferences<string>>(),
      isDisplayedInChildPages :true,
      displayChildPages:true,
        isHighlighted:false,
    };
  }

  private PrepareForSubmission(){
    this.Page.flatContent = this.flatContent;
    this.Page.state = {value:this.ConvertBoolToActiveStateValue(this.IsItemActive)};
  }

  //TODO call base class method after PrepareForSubmission
  protected SubmitToServer():Promise<IContentPage>{
    this.PrepareForSubmission();
    return this.ProcessServerRequest<IContentPage>(this.contentService.AddPage(this.Page));
  }

  Submit(){
    super.Submit();

  }

  ProcessReceived(obj:any){
    super.RedirectToURL(this.SuccessRedirectURLBase, obj._id);
  }

  ngOnInit() {
    super.GetItemHierarchyFromRoute(this.route);
    super.ngOnInit();
    this.IsItemActive = true;
  }

  protected InitialiseContentForDisplayInEditor():Promise<void>{
    return new Promise((resolve,reject)=> {
      this.CreatePage();
      resolve();
    });
  }

}
