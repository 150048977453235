import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {TextSnippet} from "@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component";
import {SnippetService} from "@/_services/snippet.service";

@Component({
    selector: 'app-text-snippet',
    templateUrl: './text-snippet.component.html',
    styleUrls: ['../../app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TextSnippetComponent extends AbstractContentEditor implements OnInit {
    @Input() public Snippet: TextSnippet;
    @Input() public Title: string;
    @Input() public WYSIWYGEnabled: boolean = true;
    @Input() public ReferencesEnabled: boolean;
    //ReferencesEnabled = true;//this.SupportCitations;
    @Input() public EditorHeight = 250;

    constructor(
        protected service: SnippetService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected InitialiseContentForDisplayInEditor(): Promise<void> {
        //There's no initialising to do, but the calling method expects a promise
        return new Promise((resolve, reject) => {
            resolve();
        });
    }

    protected SubmitToServer(): Promise<void> {
        if (this.Snippet.existing) {
            return this.UpdateSnippet();
        } else {
            //TODO get the resulting object and overwrite the local one, so we have the new ID
            return this.AddSnippet();
        }
    }

    private UpdateSnippet(): Promise<void> {
        console.log(this.Snippet);
        if (this.ReferencesEnabled) {
            return this.ProcessServerRequest(this.service.UpdateCitedSnippet(this.Snippet));
        } else {
            return this.ProcessServerRequest(this.service.UpdateTextSnippet(this.Snippet));
        }
    }

    private AddSnippet(): Promise<void> {
        if (this.ReferencesEnabled) {
            return this.ProcessServerRequest(this.service.AddCitedSnippet(this.Snippet));
        } else {
            return this.ProcessServerRequest(this.service.AddTextSnippet(this.Snippet));
        }
    }
}
