import {Component} from '@angular/core';
import {Validators} from "@angular/forms";
import {ConfigService} from "@/_services";
import {IsNumberValidator, IsWholeNumberValidator} from "@/_helpers/Functions";
import {AbstractSiteSettingsComponent} from "@/_components/site-settings/AbstractSiteSettingsComponent";

@Component({
  selector: 'app-blast-settings',
  templateUrl: './blast-settings.component.html',
  styleUrls: ['./blast-settings.component.css']
})
export class BlastSettingsComponent extends AbstractSiteSettingsComponent {
    BlastRequestsMax=10;
    BlastRequestsMin=1;
    SequenceLengthMin=100;
    SequenceLengthMax=2000;
    MaxBlastRequestValidators = [Validators.required, Validators.min(this.BlastRequestsMin), Validators.max(this.BlastRequestsMax),IsNumberValidator,IsWholeNumberValidator];
    MinSequenceLengthValidators = [Validators.required, Validators.min(this.SequenceLengthMin),Validators.max(this.SequenceLengthMax),IsNumberValidator,IsWholeNumberValidator];
    MaxSequenceLengthValidators = [Validators.required, Validators.min(this.SequenceLengthMin),Validators.max(this.SequenceLengthMax),IsNumberValidator,IsWholeNumberValidator];

  constructor(
      protected configService:ConfigService) {
      super(configService);
  }
}



