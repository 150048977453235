import {Inject, OnInit, Optional} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


export class AbstractPopup<ObjType,PopupType> implements OnInit {
    errors:object = {};
    action:string;
    local_data:ObjType;

    constructor(
        public dialogRef: MatDialogRef<PopupType>,
        //@Optional() is used to prevent error if no data is passed
        @Optional() @Inject(MAT_DIALOG_DATA) public data: PopupInput<ObjType>
    ) {
        this.local_data = {...data.object};
        this.action = this.data.action;
    }

    doAction(){
        this.dialogRef.close({event:this.action,data:this.local_data});
    }

    closeDialog(){
        this.dialogRef.close({event:'Cancel'});
    }

    ngOnInit(): void {
    }

    AddError(index:string, error:string){
        this.errors[index] = error;
    }

    RemoveError(index:string){
        this.errors[index] = "";
    }

    HasErrors(){
        let size = 0, key;
        for (key in this.errors) {
            if (this.errors.hasOwnProperty(key)) size++;
        }
        return size > 0;
    };

    GetErrors(){
        return Object.values(this.errors).filter(x=>x!="") as string[];
    }
}

export class PopupInput<T>{
    constructor(action:string, obj:T) {
        this.action = action;
        this.object = obj;
    }
    action:string;
    object:T;
}