import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

export abstract class AbstractService {
    protected apiURL:string = `${environment.settings.apiBaseUrl}`;

    protected httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE"//,
            //"Accept": "application/json"
            //'Authorization': 'my-auth-token'
        })
    };

    protected constructor(protected http:HttpClient, urlExtension:string) {
        this.apiURL = this.apiURL + urlExtension+'/';
    }
}