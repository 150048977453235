import {Component, Input, OnInit} from '@angular/core';
import {UploadService} from "@/_services/UploadService";
import {MicrobeService} from "@/_services";
import {AbstractFormDataUploadComponent} from "@/_components/common/upload/AbstractFormDataUploadComponent";

@Component({
  selector: 'app-microbe-details-update',
    templateUrl: '../common/upload/upload.component.html',
    styleUrls: ['../common/upload/upload.component.css'],
  providers: [{provide: UploadService, useClass: MicrobeService}]
})
export class MicrobeDetailsUpdateComponent extends AbstractFormDataUploadComponent implements OnInit {
    @Input() AllowedFileTypes = ".xls,.xlsx"
    Intro = "<h1>Update microbe field guide details</h1>\n" +
        "<p>Upload a new details file below and the details will be automatically updated.</p>\n" +
        "<p>Note: file must be in <strong>.xls</strong> or <strong>.xlsx</strong> format.</p>\n";

    constructor(protected service: UploadService) {
        super(service);
    }

    protected GetResponseValue(response: any) {
       // console.log(response);
        return response ? response.value : null;
    }

    ngOnInit() {

    }
}

