import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { ICharacteristic, IEnvironment} from "@/_models/characteristic";
import { AbstractService } from "@/_services/AbstractService";
import {ISearchResponse} from "@/_services/microbe.service";

@Injectable({ providedIn: 'root' })
export class UIService extends AbstractService{
    constructor(protected http: HttpClient) {
        super(http,"presentation");
    }

    private _propertiesApiExtension = "properties";
    private _metabolismsApiExtension = "metabolisms";
    private _environmentsApiChunk = "environments/";
    private _descriptionApiChunk = "description/";
    private _environmentsApiExtension = "characteristic/" + this._environmentsApiChunk;
    private _descriptionsApiExtension = "characteristic/" + this._descriptionApiChunk;

    public GetMetabolisms() : Observable<ICharacteristic[]>{
        return this.GetCharacteristics(this._metabolismsApiExtension);
    }

    private UpdateCharacteristics(values:ICharacteristic[], routeExtension:string) : Observable<void>{
        return this.http.post<void>(`${this.apiURL}` + this._descriptionsApiExtension + routeExtension, values);
    }

    public UpdateProperties(values:ICharacteristic[]) : Observable<void>{
        return this.UpdateCharacteristics(values,this._propertiesApiExtension);
    }

    public UpdateMetabolisms(values:ICharacteristic[]) : Observable<void>{
        return this.UpdateCharacteristics(values,this._metabolismsApiExtension);
    }

    public UpdatePropertyEnvironments(values:IEnvironment[]) : Observable<void>{
        return this.UpdateEnvironments(values, this._propertiesApiExtension);
    }

    public UpdateMetabolismEnvironments(values:IEnvironment[]) : Observable<void>{
        return this.UpdateEnvironments(values,this._metabolismsApiExtension);
    }

    private UpdateEnvironments(values:IEnvironment[], routeExtension:string) : Observable<void>{
        return this.http.post<void>(`${this.apiURL}`+ this._environmentsApiExtension + routeExtension, values);
    }

    public GetProperties() : Observable<ICharacteristic[]> {
        return this.GetCharacteristics(this._propertiesApiExtension);
    }


    public GetMetabolismEnvironments() : Observable<IEnvironment[]>{
        return this.GetEnvironments(this._metabolismsApiExtension);
    }

    public GetPropertyEnvironments() : Observable<IEnvironment[]>{
        return this.GetEnvironments(this._propertiesApiExtension);
    }

    private GetEnvironments(routeExtension:string) : Observable<IEnvironment[]>{
        return this.http.get<IEnvironment[]>(`${this.apiURL}`+ this._environmentsApiChunk + routeExtension);
    }

    private GetCharacteristics(routeExtension:string) : Observable<ICharacteristic[]>{
        return this.http.get<ICharacteristic[]>(`${this.apiURL}${routeExtension}`);
    }

    GetMicrobeNameMatches(query:string,includeAltNames:boolean = false, speciesIncluded:boolean = false, generaIncluded:boolean = false) : Observable<ISearchResponse>{
        let UrlPathParams = "";
        if(generaIncluded){
            UrlPathParams+="/genus";
        }
        if(speciesIncluded){
            UrlPathParams+="/species";
        }

        if(includeAltNames){
            UrlPathParams+="/altnames";
        }

        if(UrlPathParams=="") {
            return new Observable<ISearchResponse>();
        }


        return this.http.get<ISearchResponse>(`${this.apiURL}search_suggestions${UrlPathParams}/${query}`);
    }

}