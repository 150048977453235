import {Component, OnInit} from '@angular/core';
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {AuthenticationService} from "@/_services";
import {UserDto} from "@/_models";

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent extends AbstractDisplayComponent implements OnInit {

    public Users : Array<UserDto>;

    constructor(protected userService:AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        this.LoadContent();
    }

    DeleteUser(user:UserDto){
        this.Submit();
        this.ProcessServerRequest(this.userService.DeleteUser(user)).then(result=>{
            this.Users = this.Users.filter(x=>x.username != user.username);
            this.SetStatusMessage("User: "+ user.username + " deleted successfully.")
        }).catch(error=>{
            this.AfterErrorReceived(error);
        }).finally(()=>{

        });
    }

    LoadContent(){
        this.SetLoadingState();
        this.ProcessServerRequest(this.userService.GetAllUsers()).then(users=>{
            this.Users = users;
        }).catch(error=>{
            this.AfterErrorReceived(error,"Error getting user list.");
        }).finally(()=>{
            this.SetFinishedLoadingState();
        })
    }

}
