import { HttpClient } from '@angular/common/http';
import {UploadService} from '@/_services/UploadService';

export class GlobalAbundanceServiceAS extends UploadService {
    constructor(protected http: HttpClient) {
        super(http, 'global_abundance/as');
    }

    public UploadFile(values: FormData) {
        return this.http.post(`${this.apiURL}`, values, {reportProgress: true, observe: 'events'});
    }
}
