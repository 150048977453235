import {Component, OnInit} from '@angular/core';
import {DownloadService} from "@/_services/download.service";
import {HttpEventType} from "@angular/common/http";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";

@Component({
  selector: 'app-download-details',
  templateUrl: './download-details.component.html',
  styleUrls: ['./download-details.component.css']
})
export class DownloadDetailsComponent extends AbstractDisplayComponent implements OnInit {

  constructor(
      protected service: DownloadService) {
      super();
  }

  ngOnInit() {
  }

  DownloadFile(){
      this.download();
  }

    public download() {
        //this.downloadStatus.emit( {status: ProgressStatusEnum.START});
        this.service.DownloadDetails().subscribe(
            data => {

                switch (data.type) {
                    case HttpEventType.DownloadProgress:
                        //this.downloadStatus.emit( {status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100)});
                        break;
                    case HttpEventType.Response:
                        let contentDisposition = data.headers.get('content-disposition').split(';');
                        let fileName = contentDisposition[1].split('=')[1];
                        //this.downloadStatus.emit( {status: ProgressStatusEnum.COMPLETE});
                        const downloadedFile = new Blob([data.body], { type: data.body.type });
                        const a = document.createElement('a');
                        a.setAttribute('style', 'display:none;');
                        document.body.appendChild(a);
                        a.download =  fileName;
                        a.href = URL.createObjectURL(downloadedFile);
                        a.target = '_blank';
                        a.click();
                        document.body.removeChild(a);
                        break;
                }
            },
            error => {
                //this.downloadStatus.emit( {status: ProgressStatusEnum.ERROR});
            }
        );
    }

}
