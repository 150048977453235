import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-files-template',
  templateUrl: './files-template.component.html',
  styleUrls: ['./files-template.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class FilesTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
