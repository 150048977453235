import {EventEmitter, Output} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {IUploader} from '@/_services/UploadService';

import {AbstractDisplayComponent} from './AbstractDisplayComponent';

export abstract class AbstractUploadComponent extends AbstractDisplayComponent {
    public fileKey = '';
    public Intro = '';
    public PROGRESS = 0;
    public progress: number;
    public message: string;

    public UPLOAD_COMPLETE = false;
    public response: any;
    @Output() public onUploadFinished = new EventEmitter();

    protected constructor(protected uploader: IUploader<any>) {
        super();
    }

    // inheritors should call SubmitFile() once any pre submission object manipulation is complete
    protected abstract SubmitUpload();
    protected abstract GetResponseValue(response: any);

    public SubmitFile(object: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.Submit();
            this.UploadFile(object).then(response => {
                this.response = this.GetResponseValue(response);
                this.SetStatusMessage('File processing complete');
                resolve();
            }).catch(error => {
                this.AfterErrorReceived(error, 'Error uploading file.');
                reject();
            }).finally(() => {

            });
        });
    }

    private UploadFile(object: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.uploader.Upload(object).subscribe(event => {
                this.MonitorProgress(event).then(() => {
                    resolve(event.body);
                });

            }, error => {
                this.SetResponseReceivedStatus();
                reject(error);
            });
        });
    }

    private SetUploadComplete() {
        this.UPLOAD_COMPLETE = true;
        this.SetStatusMessage('Upload complete.');
    }

    private ResetUploadComplete() {
        this.UPLOAD_COMPLETE = false;
    }

    protected ResetState() {
        super.ResetState();
        this.ResetProgress();
        this.ResetUploadComplete();
    }

    ResetProgress() {
        this.PROGRESS = 0;
    }

    UpdateProgress(newProgress: number) {
        this.PROGRESS = newProgress;
        if (this.PROGRESS == 100) {
            this.SetUploadComplete();
        }
    }

    MonitorProgress(event): Promise<void> {
        return new Promise<void>((resolve) => {
            if (event.type === HttpEventType.UploadProgress) {
                this.UpdateProgress(Math.round(100 * event.loaded / event.total));
            } else if (event.type === HttpEventType.Response) {
                    this.SetResponseReceivedStatus();
                    this.SetStatusMessage('Upload complete');
                    resolve();
                }
        });
    }
}

export interface IMicrobeUpdateResponse {
    errors: Array<string>;
    updated: Array<string>;
    noMatchFound: Array<string>;
    // notUpdated:Array<string>;
}


