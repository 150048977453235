import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.css']
})
export class ContentMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
