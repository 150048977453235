import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ContentService} from "@/_services/content.service";
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {IMenuLink, IValueString} from "@/_models/IContent";
import * as language from "../../../Language/language.json";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-content-edit',
  templateUrl: '../content-link-template.html',
  styleUrls: ['../content-editing.css'],
    encapsulation:ViewEncapsulation.None
})
export class AddLinkComponent extends AbstractContentEditor implements OnInit {
    Title="Add new link: ";
    ButtonText = language.GENERIC_SAVE_BUTTON_TEXT;
    ChangesSubmittedText = language.GENERIC_SUBMITTED;
    SuccessRedirectURLBase = '/content/link/edit/';
    public Link:IMenuLink;

    constructor(
        protected contentService:ContentService,
        protected route: ActivatedRoute
        ) {
        super();
    }


    CreateLink(){
        this.Link = <IMenuLink>{
            parentId:[this.ParentID],
            belongsTo:<IValueString>{value:this.SiteName},
            isDisplayedInChildPages :true,
            altText:"",
            isHighlighted:false,
        };
    }

    ProcessReceived(obj:any){
        super.RedirectToURL(this.SuccessRedirectURLBase, obj._id);
    }


    private PrepareForSubmission(){
        this.Link.state ={value:this.ConvertBoolToActiveStateValue(this.IsItemActive)};
    }

    protected SubmitToServer():Promise<IMenuLink>{
        this.PrepareForSubmission();
        return this.ProcessServerRequest<IMenuLink>(this.contentService.AddLink(this.Link));
    }

    Submit(){
        super.Submit();
    }

    ngOnInit() {
        super.GetItemHierarchyFromRoute(this.route);
        super.ngOnInit();
        this.IsItemActive = true;
    }

    protected InitialiseContentForDisplayInEditor():Promise<void>{
        return new Promise((resolve,reject)=> {
            this.CreateLink();
            resolve();
        });
    }
}
