import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IReceivedFile, ITaxonomyVersionSubmit, TaxonomyVersionPair} from "@/_models/TaxonomyVersion";
import {TaxonomyUpdateVersionService} from "@/_services/taxonomyUpdateVersion.service";
import {AbstractUploadComponent} from "@/_components/AbstractUploadComponent";
import {DragDropHelper} from "@/_services/drag-drop.helper";

@Component({
    selector: 'app-taxonomy-version-edit',
    templateUrl: './taxonomy-version-edit.component.html',
    styleUrls: ['./taxonomy-version-edit.component.css', "../../_forms/forms.css", "../../dragula.css"]
})
export class TaxonomyVersionEditComponent extends AbstractUploadComponent implements OnInit {
    @Output() ReloadVersions: EventEmitter<any> = new EventEmitter();
    UPDATE_METHOD = "update";
    DELETE_METHOD = "delete";

    private _selectedVersion: TaxonomyVersionPair;

    @Input() set SelectedVersion(value: TaxonomyVersionPair) {
        this._selectedVersion = value;
        this.ResetState();
    }

    get SelectedVersion(): TaxonomyVersionPair {
        return this._selectedVersion;
    }

    //TODO on selected version change, reset states

    constructor(
        public dragDropper: DragDropHelper,
        protected taxonomyVersionService: TaxonomyUpdateVersionService,

        ) {
        super(taxonomyVersionService);
    }

    ngOnInit() {
        //super.InitialiseDragula();
        this.SetFinishedLoadingState();
    }

    protected GetResponseValue(response: any) {
        return response ? response.value : null;
    }


    handleSelectedUpdateMethod(method) {
        if (method == this.UPDATE_METHOD) {
            this._selectedVersion.submitting.edit = true;
            this._selectedVersion.submitting.delete = false;
        } else if (method == this.DELETE_METHOD) {
            this._selectedVersion.submitting.edit = false;
            this._selectedVersion.submitting.delete = true;
        }
    }

    ValidateVersion(version: ITaxonomyVersionSubmit) {
        version.files = version.files.filter(x => x.file && x.title != "");
    }

    UpdateFileOrder(event) {
        this._selectedVersion.submitting.existingFiles = event;
        this.ApplyOrderPositions(this._selectedVersion.submitting.existingFiles);
    }

    ApplyOrderPositions(collection: IReceivedFile[]) {
        let pos = 0;

        collection.forEach(x => {
            x.orderPosition = pos;
            pos++;
        });
        return collection;
    }

    SubmitUpload() {
        this.ResetState();
        this.SetLoadingState();
        this.ValidateVersion(this._selectedVersion.submitting);

        super.SubmitFile(this.SelectedVersion.submitting).then(() => {
            super.SetStatusMessage("Taxonomy version updated");
        }).finally(() => {
            super.SetFinishedLoadingState();
            //this._selectedVersion = null;
            this.ReloadVersions.emit();
        });
    }

}
