import {AppInjector} from "@/_services/app-injector.service";
import {Injector} from "@angular/core";

export abstract class AbstractComponentBase{
    protected injector:Injector;


    protected constructor(){
        this.InitialiseInjector();
        this.GetDependencies();
    }

    protected InitialiseInjector(){
        this.injector = AppInjector.getInjector();
    }

    protected GetDependencies(){

    }
}
