import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMenuLink} from "@/_models/IContent";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {DragDropHelper} from "@/_services/drag-drop.helper";
import {GetPageDisplayClass} from "@/_helpers/Functions";
import {SubSiteContent} from "@/_models/SubsiteContent";


@Component({
  selector: 'app-page-arrange-sub-site-root',
  templateUrl: './page-arrange-sub-site-root.component.html',
  styleUrls: ['./page-arrange-sub-site-root.component.css',"../../_forms/forms.css", "../../dragula.css"]
})
export class PageArrangeSubSiteRootComponent extends AbstractDisplayComponent implements OnInit {
    @Input() Site:SubSiteContent;
    @Input() ListClass:string="";
    @Output() onMessageSet: EventEmitter<string> = new EventEmitter();
    @Output() onErrorSet: EventEmitter<string> = new EventEmitter();
    @Output() onStatusReset: EventEmitter<void> = new EventEmitter();
    @Output() onDelete: EventEmitter<string> = new EventEmitter();
    expandPanel:boolean = false;

    constructor(public dragDropper: DragDropHelper) {
        super();
    }

    ngOnInit() {

    }

    TransmissionComplete() {

    }

    public SetErrorMessage(error: string) {
        this.onErrorSet.emit(error);
    }

    public SetMessage(message: string) {
        this.onMessageSet.emit(message);
    }

    public ResetStatus() {
        this.onStatusReset.emit();
    }

    public DeleteItem(){
        this.onDelete.emit();
    }

    public DeleteItemAndRemove(item :IMenuLink){
        this.Site.RemoveLink(item);
        this.onDelete.emit();
    }

    GetPageClass(link:IMenuLink){
        return GetPageDisplayClass(link);
    }


}
