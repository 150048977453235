import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractContentIndex} from "@/_components/content-management/abstract-content-index.component";
import {ContentService} from "@/_services";
import {IMenuLink} from "@/_models/IContent";
import * as language from "../../../Language/language.json";

@Component({
  selector: 'app-content-arrange',
  templateUrl: './content-arrange.component.html',
  styleUrls: ['./content-arrange.component.css'],
    encapsulation:ViewEncapsulation.None
})

export class ContentArrangeComponent extends AbstractContentIndex implements OnInit {
    @Input() ListClass: string = "children";
    ButtonText=language.GENERIC_SAVE_BUTTON_TEXT;
    STATUS_MESSAGE = "";

    constructor(
        protected service: ContentService
    ) {
        super();
    }

    ngOnInit(): void {
        this.LoadContent();
    }

    Submit(){
        super.SetLoadingState();
        super.SetStatusMessage(language.GENERIC_SUBMITTED);
        super.Submit();
        let prepared = this.PrepareForSubmission();
        this.SubmitToServer(prepared).then(()=>{
            super.SetStatusMessage(language.GENERIC_SAVE_SUCCESS);
            this.LoadContent()
        }).catch(error=>{
            this.AfterErrorReceived(error, "Error saving new site hierarchy")
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });
    }

    public ChildSetError($event){
        this.AfterErrorReceived(null, $event);
    }

    public ChildResetState(){
        this.ResetState();
    }

    PrepareForSubmission() {
        let flattenedSites = new Array<IMenuLink>();
        this.SubSites.forEach(subSite=>{
            flattenedSites= flattenedSites.concat(subSite.GetAPIAcceptablePageArray());
        });
        return flattenedSites;
    }

    SubmitToServer(pages:Array<IMenuLink>):Promise<void>{
        return this.ProcessServerRequest(this.service.ReorderPages(pages));
    }


}

