import { Routes, RouterModule } from '@angular/router';



import { AuthGuard } from './_guards';
import {TaxonomyTemplateComponent} from '@/_components/taxonomy-management/taxonomy-template/taxonomy-template.component';
import {CharacteristicsTemplateComponent} from '@/_components/characteristic-management/characteristics-template/characteristics-template.component';
import {TaxonomyMenuComponent} from '@/_components/_menus/taxonomy-menu/taxonomy-menu.component';
import {CharacteristicMenuComponent} from '@/_components/_menus/characteristic-menu/characteristic-menu.component';
import {CharacteristicsHomeComponent} from '@/_components/characteristic-management/characteristics-home/characteristics-home.component';
import {CharacteristicsEditPropertiesComponent} from '@/_components/characteristic-management/characteristic-editing/characteristics-edit-properties.component';
import {CharacteristicsEditMetabolismsComponent} from '@/_components/characteristic-management/characteristic-editing/characteristics-edit-metabolisms.component';
import {EnvironmentsEditMetabolismsComponent} from '@/_components/characteristic-management/environment-editing/environments-edit-metabolisms.component';
import {EnvironmentsEditPropertiesComponent} from '@/_components/characteristic-management/environment-editing/environments-edit-properties.component';
import {TaxonomyHomeComponent} from '@/_components/taxonomy-management/taxonomy-home/taxonomy-home.component';
import {TaxonomyVersionAddComponent} from '@/_components/taxonomy-management/taxonomy-version-add/taxonomy-version-add.component';
import {MicrobeDetailsUpdateComponent} from '@/_components/taxonomy-management/microbe-details-update.component';
import {EditContentPageComponent} from '@/_components/content-management/edit/edit-content-page.component';
import {ContentComponent} from '@/_components/content-management/template-content/content.component';
import {ContentHomeComponent} from '@/_components/content-management/home-content/content-home.component';
import {AbstractContentIndex} from '@/_components/content-management/abstract-content-index.component';
import {ContentArrangeComponent} from '@/_components/content-management/arrange-home/content-arrange.component';
import {SiteSettingsComponent} from '@/_components/site-settings/site-settings-template/site-settings.component';
import {MatchingComponent} from '@/_components/taxonomy-management/matching/matching/matching.component';
import {AddContentComponent} from '@/_components/content-management/add/add-content.component';
import {DanishAbundanceUpdateComponent} from '@/_components/taxonomy-management/danish-abundance-update.component';
import {AbundanceComponent} from '@/_components/taxonomy-management/danish-abundance-management/abundance.component';
import {UpdateDatabaseTaxonomyComponent} from '@/_components/taxonomy-management/update-database-taxonomy.component';
import {ReportManagementComponent} from '@/_components/document-management/report-management/report-management.component';
import {AddReportComponent} from '@/_components/document-management/report-document/add-report/add-report.component';
import {AddReportCategoryComponent} from '@/_components/document-management/report-category/add-report-category/add-report-category.component';
import {EditReportCategoryComponent} from '@/_components/document-management/report-category/edit-report-category/edit-report-category.component';
import {UsersTemplateComponent} from '@/_components/user-management/users-template/users-template.component';
import {RegisterUserComponent} from '@/_components/user-management/register-user/register-user.component';
import {ListUsersComponent} from '@/_components/user-management/list-users/list-users.component';
import {ReparesUpdateComponent} from '@/_components/taxonomy-management/repares-update.component';
import {GlobalAbundanceUpdateADComponent} from '@/_components/taxonomy-management/global-abundance-update-ad.component';
import {GlobalAbundanceUpdateASComponent} from '@/_components/taxonomy-management/global-abundance-update-as.component';
import {ProcessTypeSnippetManagementComponent} from '@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component';
import {GlobalContributorsSnippetManagementComponent} from '@/_components/text-snippet-management/global-contributors-snippet-management/global-contributors-snippet-management.component';
import {ContentSnippetManagementComponent} from '@/_components/content-snippet-management/content-snippet-management.component';
import {DownloadDetailsComponent} from '@/_components/taxonomy-management/download-details/download-details.component';
import {WwtpsContributorsComponent} from '@/_components/text-snippet-management/global-contributors-snippet-management/wwtps-contributors.component';
import {AdContributorsComponent} from '@/_components/text-snippet-management/global-contributors-snippet-management/ad-contributors.component';
import {EditLinkComponent} from '@/_components/content-management/edit/edit-link.component';
import {AddLinkComponent} from '@/_components/content-management/add/add-link.component';
import {TaxonomySettingsComponent} from '@/_components/site-settings/taxonomy-settings/taxonomy-settings.component';
import {GeneralSettingsComponent} from '@/_components/site-settings/general-settings/general-settings.component';
import {BlastSettingsComponent} from '@/_components/site-settings/blast-settings/blast-settings.component';
import {TaxonomyVersionManagementComponent} from '@/_components/taxonomy-management/taxonomy-version-management/taxonomy-version-management.component';
import {HomeComponent} from '@/_components/home/home.component';
import {LoginComponent} from '@/_components/login/login.component';
import {EditUserComponent} from '@/_components/user-management/edit-user/edit-user.component';
import {FileManagerComponent} from '@/_components/file-manager/file-manager.component';
import {FilesTemplateComponent} from '@/_components/file-manager/files-template/files-template.component';
import {
  GlobalAbundanceManagementComponent
} from '@/_components/taxonomy-management/global-abundance-management/global-abundance-management.component';

const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'taxonomy',
        component: TaxonomyTemplateComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', canActivate: [AuthGuard], component: TaxonomyHomeComponent},
            {path: 'update-taxonomy', canActivate: [AuthGuard], component: UpdateDatabaseTaxonomyComponent},
            {path: 'add-archive', canActivate: [AuthGuard], component: TaxonomyVersionAddComponent},
            {path: 'manage-archive', canActivate: [AuthGuard], component: TaxonomyVersionManagementComponent},
            // {path: 'update-esvs',canActivate: [AuthGuard], component: EsvUpdateComponent},
            {path: 'update-microbe-details', canActivate: [AuthGuard], component: MicrobeDetailsUpdateComponent},
            {path: 'matching', canActivate: [AuthGuard], component: MatchingComponent},
            {path: 'abundance', canActivate: [AuthGuard], component: AbundanceComponent},
            // {path: 'global-abundance', canActivate: [AuthGuard], component: GlobalAbundanceUpdateComponent},
            {path: 'global-abundance-management', canActivate: [AuthGuard], component: GlobalAbundanceManagementComponent},
            {path: 'repares', canActivate: [AuthGuard], component: ReparesUpdateComponent},
            {path: 'details-download', canActivate: [AuthGuard], component: DownloadDetailsComponent},
            ]
    },
    {
        path: 'characteristics',
        component: CharacteristicsTemplateComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', canActivate: [AuthGuard], component: CharacteristicsHomeComponent},
            {path: 'properties', canActivate: [AuthGuard], component: CharacteristicsEditPropertiesComponent},
            {path: 'metabolisms', canActivate: [AuthGuard], component: CharacteristicsEditMetabolismsComponent},
            {path: 'properties/environments', canActivate: [AuthGuard], component: EnvironmentsEditPropertiesComponent },
            {path: 'metabolisms/environments', canActivate: [AuthGuard], component: EnvironmentsEditMetabolismsComponent},
        ]
    },
    {
        path: 'content',
        component: ContentComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', canActivate: [AuthGuard], component: ContentHomeComponent},
            {path: 'arrange', canActivate: [AuthGuard], component: ContentArrangeComponent},
            {
                path: 'page',
                canActivate: [AuthGuard],
                children: [
                    {path: '', canActivate: [AuthGuard], component: ContentHomeComponent},
                    {path: 'edit/:id', canActivate: [AuthGuard], component: EditContentPageComponent},
                    {path: 'add/:site/:parentid', canActivate: [AuthGuard], component: AddContentComponent},
                    {path: 'add/:site', canActivate: [AuthGuard], component: AddContentComponent},
                ]
            },
            {
                path: 'link',
                canActivate: [AuthGuard],
                children: [
                    {path: '', canActivate: [AuthGuard], component: ContentHomeComponent},
                    {path: 'edit/:id', canActivate: [AuthGuard], component: EditLinkComponent},
                    {path: 'add/:site/:parentid', canActivate: [AuthGuard], component: AddLinkComponent},
                    {path: 'add/:site', canActivate: [AuthGuard], component: AddLinkComponent},
                ]
            },
            {path: 'process-type-description-index', canActivate: [AuthGuard], component: ProcessTypeSnippetManagementComponent},
            {path: 'global-ad-contributors-index', canActivate: [AuthGuard], component: AdContributorsComponent},
            {path: 'global-wwtps-contributors-index', canActivate: [AuthGuard], component: WwtpsContributorsComponent},
            {path: 'snippets-index', canActivate: [AuthGuard], component: ContentSnippetManagementComponent},
        ]
    },
    {
        path: 'documents',
        component: ContentComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'manage-reports', canActivate: [AuthGuard], component: ReportManagementComponent},
            // {path: 'edit/:id',canActivate: [AuthGuard], component: EditContentComponent},
            // {path: 'add/:site/:parentid',canActivate: [AuthGuard], component: AddContentComponent},
            {path: 'add/:category', canActivate: [AuthGuard], component: AddReportComponent},
            {path: 'categories/add/site/:site', canActivate: [AuthGuard], component: AddReportCategoryComponent},
            {path: 'categories/add/parent/:parent', canActivate: [AuthGuard], component: AddReportCategoryComponent},
            {path: 'categories/edit/:category', canActivate: [AuthGuard], component: EditReportCategoryComponent},
        ]
    },

    {
        path: 'users',
        component: UsersTemplateComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'add', canActivate: [AuthGuard], component: RegisterUserComponent},
            {path: 'edit/:username', canActivate: [AuthGuard], component: EditUserComponent},
            {path: 'list', canActivate: [AuthGuard], component: ListUsersComponent},
        ]
    },
    {
        path: 'files',
        component: FilesTemplateComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'list', canActivate: [AuthGuard], component: FileManagerComponent},
        ]
    },

    {
        path: 'settings',
        component: SiteSettingsComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', canActivate: [AuthGuard], component: TaxonomySettingsComponent},
            {path: 'taxonomy', canActivate: [AuthGuard], component: TaxonomySettingsComponent},
            {path: 'general', canActivate: [AuthGuard], component: GeneralSettingsComponent},
            {path: 'blast', canActivate: [AuthGuard], component: BlastSettingsComponent},
        ]
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
