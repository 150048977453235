import { HttpClient } from '@angular/common/http';
import {UploadService} from '@/_services/UploadService';
import {IMicrobeUpdateResponse} from '@/_components/AbstractUploadComponent';

export class AbundanceService extends UploadService {
    constructor(protected http: HttpClient) {
        super(http, 'abundance');
    }

    public UploadFile(values: FormData) {
        return this.http.post<IMicrobeUpdateResponse>(`${this.apiURL}`, values, {reportProgress: true, observe: 'events'});
    }
}
