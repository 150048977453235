import {UIService} from "@/_services/ui.service";
import {IEnvironment} from "@/_models/characteristic";
import {ApplyOrderPositions, Sort} from "@/_models/TaxonomyVersion";
import {Observable} from "rxjs";
import {DragDropHelper} from "@/_services/drag-drop.helper";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";


//TODO very similar to abstract-edit-characteristics.component should probably make another abstarct class as base for both
export abstract class AbstractEditEnvironmentsComponent extends AbstractDisplayComponent{
  protected tooltipService : UIService;
  public dragDropper: DragDropHelper;
  Environments: IEnvironment[] = [];
  EnvironmentTypeName:string;

  protected constructor() {
    super();
    this.GetDependencies();
  }

  protected GetDependencies(){
    this.dragDropper = this.injector.get(DragDropHelper);
    this.tooltipService = this.injector.get(UIService);
  }

  protected LoadContent(serverRequest:Observable<IEnvironment[]>) : Promise<IEnvironment[]>{
    this.SetLoadingState();
    return this.ProcessServerRequest(serverRequest);
  }

  //TODO could this be put in parent class then used by all components?
  protected Init(serverRequest:Observable<IEnvironment[]>){
    this.LoadContent(serverRequest).then(result=>{
      Sort(result);
      this.Environments = result;
    }).catch(error=>{
      this.AfterErrorReceived(error);
    }).finally(()=>{
      this.SetFinishedLoadingState();
    });
  }

  //TODO could this be put in parent class then used by all components?
  Submit(){
    super.Submit();
    this.SetLoadingState();
    ApplyOrderPositions(this.Environments);
    this.SubmitRequestToServer().then(()=>{
      super.SetStatusMessage(this.EnvironmentTypeName + " updated");
    }).catch(error=>{
      this.AfterErrorReceived(error, "Error saving environment data");
    }).finally(()=>{
      this.TransmissionComplete();
      this.SetFinishedLoadingState();
    })
  }

  protected abstract SubmitRequestToServer() : Promise<void>;

}
