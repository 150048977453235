import {ApplyOrderPositions,ISortable} from "@/_models/TaxonomyVersion";
import {DragulaService} from "ng2-dragula";
import {AbstractComponentBase} from "@/_components/AbstractComponentBase";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })

export class DragDropHelper extends AbstractComponentBase{
    protected dragulaService:DragulaService
    handleClass:string="handle";
    defaultDragulaGroup:string='HANDLES';
    dragulaGroup:string = null;
    defaultDragulaListClass:string="arrange";
    alternateDragulaListClass:string="arrange alternate";

    constructor() {
        super();
        this.GetDependencies();
        this.Initialise();
    }

    protected GetDependencies(){
        this.dragulaService = this.injector.get(DragulaService);
    }

    ApplyOrderPositions(collection:ISortable[]){
        return ApplyOrderPositions(collection);
    }

    //users-template should set and handle related variables before initialising
    public Initialise() {
        let groupTag = this.dragulaGroup || this.defaultDragulaGroup;
        if (!this.dragulaService.find(groupTag)) {
            this.dragulaService.createGroup(groupTag, {
                moves: (el, container, handle) => {
                    return handle.classList.contains(this.handleClass);
                },
                invalid: function (el, handle) {
                    return el.tagName === 'ul';
                },
                accepts: (el, target, source) => {
                    // don't allow the element to be dragged into one of its children
                    return !el.contains(target);
                }
            });
        }
    }
}
