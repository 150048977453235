import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {
    GlobalContributorSnippet,
    ProcessTypeSnippet
} from "@/_components/text-snippet-management/process-type-snippet-management/process-type-snippet-management.component";
import {AbstractContentEditor} from "@/_components/content-management/abstract-content-editor";
import {UploadService} from "@/_services/UploadService";
import {SnippetService} from "@/_services/snippet.service";
import {ImageService} from "@/_services/image.service";
import {PopupInput} from "@/_components/_forms/abstract-popup";
import {DeleteConfirmationComponent} from "@/_components/_forms/delete-confitmation/delete-confirmation.component";
import {MatDialog} from "@angular/material";

@Component({
    selector: 'app-process-type-form',
    templateUrl: './process-type-form.component.html',
    styleUrls: ['../../app.component.css'],
    providers: [{provide: UploadService, useClass: ImageService}],
    encapsulation: ViewEncapsulation.None
})
export class ProcessTypeFormComponent extends AbstractContentEditor implements OnInit {
    @Input() public Snippet: ProcessTypeSnippet;
    ReferencesEnabled = false;
    EditorHeight = 150;
    @Output() DeleteSnippet: EventEmitter<any> = new EventEmitter();
    @Output() SetParentError: EventEmitter<string> = new EventEmitter();
    @Output() SetParentStatus: EventEmitter<string> = new EventEmitter();

    constructor(
        protected service: SnippetService,
        protected deleteConfirmDialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        //TODO only init editor when expanded the first time.
        super.ngOnInit();
    }

    protected ProcessDeletion() {
        this.SetLoadingState();
        this.ResetState();
        this.SUBMITTED = true;
        this.SetParentStatus.emit("Delete request submitted.")
        this.SendDeleteRequestToServer().then(() => {
            console.log("deleted");
            this.SetParentStatus.emit("Process type description deleted successfully");
            this.DeleteSnippet.emit(this.Snippet);
        }).catch(error => {
            this.AfterErrorReceived(error);
        }).finally(() => {
            this.SetFinishedLoadingState();
        })
    }

    protected SendDeleteRequestToServer() {
        this.ResetState();
        this.SUBMITTED = true;
        super.SetStatusMessage("Delete request submitted.")
        return this.ProcessServerRequest(this.service.DeleteSnippet(this.Snippet));
    }

    protected InitialiseContentForDisplayInEditor(): Promise<void> {
        //There's no initialising to do, but the calling method expects a promise
        return new Promise((resolve, reject) => {
            resolve();
        });
    }

    protected SubmitToServer(): Promise<void> {
        if (this.Snippet.existing) {
            return this.ProcessServerRequest(this.service.UpdateProcessType(this.Snippet));
        } else {
            //TODO get the resulting object and overwrite the local one, so we have the new ID
            return this.ProcessServerRequest(this.service.AddProcessType(this.Snippet));
        }
    }
}
