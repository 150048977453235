import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserCreationDto} from "@/_models";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css'],
    encapsulation:ViewEncapsulation.None
})
export class RegisterUserComponent extends AbstractDisplayComponent implements OnInit {
  Title = "Add new user";
  User:UserCreationDto;
  USER_TYPE="User";
  ADMIN_TYPE="Admin";
  PasswordsMatch = false;
  UserLevelEnabled = true;
  ButtonText = "Add user";


  constructor(protected router: Router,
              protected route: ActivatedRoute,
              ) {
    super();
    super.SetFinishedLoadingState();
  }

  ngOnInit() {
    this.InstantiateUserObject();
  }

  private InstantiateUserObject() {
    this.User = new UserCreationDto();
    this.User.userLevel = this.USER_TYPE;
    this.User.firstName = "";
    this.User.lastname = "";
  }

  handleSelectedUserType(type) {
    if (type == this.ADMIN_TYPE) {
      this.User.userLevel = this.ADMIN_TYPE;
    } else if (type == this.USER_TYPE) {
      this.User.userLevel = this.USER_TYPE;
    }
  }

  ComparePasswordFields(){
    this.ResetErrors();
    if(this.User.repeatPassword != this.User.password){
      this.PasswordsMatch = false;
      super.AddErrorMessage("Password fields must match")
    }
    else{
      super.SetStatusMessage("");
      this.PasswordsMatch = true;
    }
  }

  SaveUser(){
    super.Submit();
    super.SetLoadingState();
    this.ProcessServerRequest(this.authenticationService.AddUser(this.User)).then(result=>{
      super.SetStatusMessage("User added successfully");
    }).catch(error=>{
      super.AfterErrorReceived(error);
    }).finally(()=>{
      super.SetFinishedLoadingState();
    });
  }

}
