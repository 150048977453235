import {HttpClient, HttpEvent} from '@angular/common/http';
import {UploadService} from "@/_services/UploadService";
import {Observable} from "rxjs";

export class FileManagementService extends UploadService{
    constructor(protected http: HttpClient) {
        super(http,"files");
    }

    public UploadFile(values:FormData){
        return this.http.post<string>(`${this.apiURL}file`, values);
    }

    public GetAllFiles() : Observable<Array<FileReference>>{
        return this.http.get<Array<FileReference>>(`${this.apiURL}`);
    }

    public DeleteFile(file:FileReference){
        return this.http.delete<string>(`${this.apiURL}${file._id}`);
    }
}

export interface FileReference{
    _id:string;
    savedPath:string;
}

