export class TaxonomyVersionPair {
    constructor(received: ITaxonomyVersionReceive) {
        this.received = received;
        this.submitting = new TaxonomyVersionSubmit(received);
    }
    received: ITaxonomyVersionReceive;
    submitting: ITaxonomyVersionSubmit;
    selected:boolean = false;
}

export interface ITaxonomyVersion  extends ISortable{
    _id: string;
    versionNumber: number;
    description: string;
    isArchive: boolean;
    orderPosition: number;
}

export class TaxonomyVersionSubmit implements ITaxonomyVersionSubmit,ISortable{
    constructor(received?: ITaxonomyVersionReceive) {
        this.files = new Array<SubmitFile>();
        if (received) {
            this._id = received._id;
            this.description = received.description;
            this.isArchive = received.isArchive;
            this.versionNumber = received.versionNumber;
            this.orderPosition = received.orderPosition;
            this.existingFiles = received.files;
        } else {
            this.isArchive = false;
            this.existingFiles = [];
        }

        this.files.push(new SubmitFile(null, "", ""));
        this.edit = true;
        this.delete = false;
    }

    AddAnotherFile() {
        this.files.push(new SubmitFile(null, "", ""));
    }

    files: ISubmitFile[];
    existingFiles:IReceivedFile[];
    _id: string;
    description: string;
    isArchive: boolean;
    versionNumber: number;
    delete: boolean;
    edit: boolean;
    orderPosition: number;
}

export interface ITaxonomyVersionSubmit extends ISortable, ITaxonomyVersion, ITaxonomyVersionUpdateRequest {
    AddAnotherFile();
    existingFiles:IReceivedFile[];
    files: ISubmitFile[];
}

export interface ITaxonomyVersionReceive extends ITaxonomyVersion {
    files: IReceivedFile[];
}

export class SubmitFile implements ISubmitFile {
    file: File;
    title: string;
    description: string;
    fileName: string;


    constructor(file: File, title: string, description: string) {
        this.file = file;
        this.description = description;
        this.title = title;
    }
}

export interface IReceivedFile extends ISortable{
    savedPath: string;
    title: string;
    description: string;
    delete:boolean;
    orderPosition: number;
}

export interface ISubmitFile {
    file: File;
    title: string;
    description: string;
    fileName: string;
}

export interface ITaxonomyVersionUpdateRequest extends ITaxonomyVersion {
    delete: boolean;
    edit: boolean;
}

export interface ISortable {
    orderPosition: number;
}

export function Sort(collection: ISortable[]) : Array<any> {
    return collection.sort((a, b) => a.orderPosition - b.orderPosition);
}

export function ApplyOrderPositions(collection: ISortable[]) {
    let pos = 0;
    collection.forEach(x => {
        x.orderPosition = pos;
        pos++;
    });
    return collection;
}