import {Component, OnInit} from '@angular/core';
import {UIService} from "@/_services/ui.service";
import {AbstractEditCharacteristicsComponent} from "@/_components/characteristic-management/characteristic-editing/abstract-edit-characteristics.component";

@Component({
    selector: 'app-characteristics-properties',
    templateUrl: './characteristics-edit.html',
    styleUrls: ['../../dragula.css', '../../_forms/forms.css']
})
export class CharacteristicsEditPropertiesComponent extends AbstractEditCharacteristicsComponent implements OnInit{

    CharacteristicTypeName="properties";

    constructor(
        protected tooltipService : UIService
    ) {
        super();
    }

    ngOnInit() {
        this.Init(this.tooltipService.GetProperties());
    }

    protected SubmitRequestToServer(): Promise<void> {
        return super.ProcessServerRequest(this.tooltipService.UpdateProperties(this.Characteristics));
    }

}
