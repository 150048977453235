import {Component, Input, OnInit} from '@angular/core';
import {UploadService} from '@/_services/UploadService';
import {GlobalAbundanceServiceAS} from '@/_services/globalAbundanceAS.service';
import {AbstractFormDataUploadComponent} from '@/_components/common/upload/AbstractFormDataUploadComponent';

@Component({
    selector: 'app-global-abundance-update-as',
    templateUrl: '../common/upload/upload.component.html',
    styleUrls: ['../common/upload/upload.component.css'],
    providers: [{provide: UploadService, useClass: GlobalAbundanceServiceAS}]
})
export class GlobalAbundanceUpdateASComponent extends AbstractFormDataUploadComponent implements OnInit {
    @Input() title;
    @Input() key = 'AS';
    @Input() AllowedFileTypes = '.xls,.xlsx';

    constructor(protected service: UploadService) {
        super(service);
    }

    ngOnInit() {
      this.fileKey = this.key;
      this.Intro = '<h3>' + this.title + '</h3>';
    }

}
