import {Component} from '@angular/core';

@Component({
  selector: 'app-characteristics-home',
  templateUrl: './characteristics-home.component.html',
  styleUrls: ['./characteristics-home.component.css']
})
export class CharacteristicsHomeComponent  {

}
