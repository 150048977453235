import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent {

  @Input() Messages:Array<string>;
  constructor() { }

  MessagesExist(){
    if (this.Messages && this.Messages.length>0){
      return true;
    }
  }
}