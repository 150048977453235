import {FormGroup} from "@angular/forms";
import {IMenuLink} from "@/_models/IContent";
import {ContentService} from "@/_services";
import * as language from "@/Language/language.json";
import {AbstractDisplayComponent} from "../AbstractDisplayComponent";
import {SubSiteContent} from "@/_models/SubsiteContent";

export abstract class AbstractContentIndex extends AbstractDisplayComponent{
    protected contentService: ContentService;
    protected Form: FormGroup;
    SubSites: Array<SubSiteContent>;
    ContentStateDisabled = language.CONTENT_DISABLED_STATE;
    private SiteNames:Array<string>;
    ContentStateActive = language.CONTENT_ACTIVE_STATE;

    protected constructor(

    ) {
        super();
    }

    protected GetDependencies(){
        this.contentService = this.injector.get(ContentService);
    }

    LoadContent(): void {
        this.SubSites = new Array<SubSiteContent>();
        this.LoadSubSiteNames().then(siteNames=>{
            this.SiteNames = siteNames;
            this.GetAllLinks().then(links=>{
                this.InitialiseSubSiteStructures(links);
            }).catch(error=>{
                this.AfterErrorReceived(error, "Error initialising sub site data.")
            })
        }).catch(error=>{
            this.AfterErrorReceived(error, "Error getting sub site names.")
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });
    }

    private InitialiseSubSiteStructures(links : Array<IMenuLink>){
        this.SiteNames.forEach(name => {
            let linksForSite = links.filter(link => link.belongsTo.value == name);
            let subSite = new SubSiteContent(name, linksForSite);
            this.SubSites.push(subSite);
        });
    }

    private GetAllLinks():Promise<Array<IMenuLink>>{
        return this.ProcessServerRequest<Array<IMenuLink>>(this.contentService.GetExistingPages());
    }

    protected LoadSubSiteNames():Promise<string[]>{
        return this.ProcessServerRequest<string[]>(this.contentService.GetSubSiteNames());
    }
}

