import {Activatable, IMenuLink} from "@/_models/IContent";
import * as language from "@/Language/language.json";
import {FormControl} from "@angular/forms";
import {ITaxonomyVersionSubmit} from "@/_models/TaxonomyVersion";

export function GetPageDisplayClass(page:IMenuLink){
    const ContentStateDisabled = language.CONTENT_DISABLED_STATE;
    if(page.state.value==ContentStateDisabled){
        return "disabled"
    }
}

export function IsActive(item:Activatable){
    const ContentStateActive= language.CONTENT_ACTIVE_STATE;
    return item.state.value == ContentStateActive;
}

export function IsNumberValidator(control: FormControl) {
    let content = control.value;
    if (isNaN(content)) {
        return {'notNumber': {value: content}};
    }
    return null;
}

export function IsWholeNumberValidator(control: FormControl) {
    let content = control.value;
    if (!isInt(content)) {
        console.log("FAIL");
        return {'notWholeNumber': {value: content}};
    }
    return null;
}

export function isInt(n) {
    return n % 1 === 0;
}

export function ConvertTaxonomyVersion(version: ITaxonomyVersionSubmit):FormData{
    let formDataVersion = new FormData();
    formDataVersion.append('_id',version._id,);
    formDataVersion.append('description',version.description);
    formDataVersion.append('isArchive',String(version.isArchive));
    formDataVersion.append('versionNumber',String(version.versionNumber));
    formDataVersion.append('delete',String(version.delete));
    formDataVersion.append('edit',String(version.edit));
    formDataVersion.append('orderPosition',String(version.orderPosition));
    version.existingFiles.forEach(x=>{
        formDataVersion.append('existingFiles',JSON.stringify(x));
        /*if(x.delete){
            formDataVersion.append('deletingFiles',String(x.path));
        }*/
    });

    version.files.forEach(file=>{
        if(file.file == null){
            return formDataVersion;
        }
        //if(file.file && file.title) { TODO MOVE VALIDATION TO THE COMPONENT
        formDataVersion.append('files',file.file);
        file.fileName = file.file.name;
        formDataVersion.append('newFiles', JSON.stringify(file));
        //}
    });

    return formDataVersion;
}