import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "@/_services";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractDisplayComponent} from "../AbstractDisplayComponent";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends AbstractDisplayComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      protected authenticationService: AuthenticationService
  ) {
    super();
  }



  ngOnInit() {
    this.SetLoadingState();
    // reset login status
    this.authenticationService.logout();
    this.InitialiseForm();
    this.GetReturnURL();
    this.SetFinishedLoadingState();
  }

  private GetReturnURL() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  private InitialiseForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

// convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.Submit();
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.SetLoadingState();

    this.ProcessServerRequest(this.authenticationService.login(this.f.username.value, this.f.password.value)).then(result=>{
      if(result.userLevel.toLowerCase()=="admin") {
        this.NavigateToReturnURL();
      }else{
        this.authenticationService.logout();
        this.AddErrorMessage("Unauthorised");
      }
    }).catch(error=>{
      if(error.Code==401){
        this.AfterErrorReceived(error, "User is not authorised.");
      }
      else{
        this.AfterErrorReceived(error);
      }
    }).finally(()=>{
      this.SetFinishedLoadingState();
    })
  }

  private NavigateToReturnURL() {
    this.router.navigate([this.returnUrl]);
  }
}
