import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IEnvironment} from "@/_models/characteristic";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-characteristic-environment',
  templateUrl: './characteristic-environment.component.html',
  styleUrls: ['./characteristic-environment.component.css']
})
export class CharacteristicEnvironmentComponent implements OnInit {
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onMove: EventEmitter<any> = new EventEmitter();
    handleClass:string="handle";
    @Input() Environments:IEnvironment[];
    @Input() formGroup: FormGroup;
    created:boolean = false;
    submitted = false;

    loading = false;
    error = '';

    constructor(
    ) { }

    ngOnInit() {
        this.Environments.forEach(x=>{
          this.formGroup.addControl(x.label+"_desc",new FormControl());
        });
        this.created = true;
    }

    // convenience getter for easy access to form fields
    get f() { return this.formGroup.controls; }

    UpdateParentContentArray(event) {
        this.onMove.emit(event);
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.formGroup.invalid) {
            return;
        }
        this.loading = true;
        this.onSave.emit();
        this.loading = false;
    }
}
