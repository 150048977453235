import { HttpClient } from '@angular/common/http';
import {UploadService} from "@/_services/UploadService";
import {AuthenticationService} from "@/_services/authentication.service";

export class ESVService extends UploadService{
    constructor(protected http: HttpClient) {
        super(http, "esvs");
    }

    public UploadFile(values:FormData){
        return this.http.post(`${this.apiURL}`, values,{reportProgress: true, observe: 'events'});
    }
}