import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IBasicMicrobe} from "@/_components/taxonomy-management/matching/matching/matching.component";
import {noop, Observable, Observer, of} from "rxjs";
import {map, switchMap, tap} from "rxjs/operators";
import {ISearchResponse, MicrobeService, UIService} from "@/_services";
import {AbstractDisplayComponent} from "../../../AbstractDisplayComponent";

@Component({
  selector: 'app-microbe-matching',
  templateUrl: './microbe-matching.component.html',
  styleUrls: ['./microbe-matching.component.css']
})
export class MicrobeMatchingComponent extends AbstractDisplayComponent implements OnInit {
  @Input() Microbe:IBasicMicrobe;
  @Input() AllMicrobes:Array<IBasicMicrobe>;
  public loading = false;
  public delete = false;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<string> = new EventEmitter();
  MicrobeNames$: Observable<string[]>;

  SearchMatches:Array<IBasicMicrobe>;
  SearchTerm:string;
  constructor(
      private microbeService:MicrobeService,
      private uiService:UIService
              ) {
    super();
  }

  ngOnInit() {
    this.SetupMicrobeNameSuggestions();
  }

  public DeletePendingMicrobe(){
    this.onDelete.emit(this.Microbe._id);
  }

  public OverwriteOtherMicrobe(){
    this.SaveMatch();
  }

  public SaveMatch(){
    this.onSave.emit({ pendingMicrobeID: this.Microbe._id, oldMicrobeName: this.SearchTerm});
  }

  private SetupMicrobeNameSuggestions() {
    this.MicrobeNames$ = new Observable((observer: Observer<string>) => {
      observer.next(this.SearchTerm);
    }).pipe(
        switchMap((query: string) => {
          if (query) {
            let includeGenusNames = true;
            let includeAltNames = false;
            let includeSpeciesNames = true;
            return this.uiService.GetMicrobeNameMatches(query,includeAltNames,includeSpeciesNames,includeGenusNames).pipe(
                map((data: ISearchResponse) => data && data.items || []),
                tap(() => noop, error => {
                  this.AfterErrorReceived(error, 'Error getting microbe name matches');
                })
            );
          }
          return of([]);
        })
    );
  }

}
