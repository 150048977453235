import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from "../_services/authentication.service";
import {Router} from "@angular/router";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err:HttpErrorResponse) => {
            if (err.error instanceof ErrorEvent) {
                // client-side error
                let errorObject = this.CreateErrorResponseObject(err, "An error occurred")
                return throwError(errorObject);
            } else {
                // server-side errors/ response codes

                if (err.status === 401 || err.status === 403) {
                    // auto logout if 401 response returned from api
                    if (err.status != 401){

                    }
                    this.authenticationService.logout();
                    this.router.navigate(["login"]);
                }
                if (err.status === 0){
                    let errorObject = this.CreateErrorResponseObject(err,"Error contacting server, please try again later.");
                    return throwError(errorObject);
                }
                if (err.status === 204){
                    let errorObject = this.CreateErrorResponseObject(err, "The requested resource could not be found.")
                    return throwError(errorObject);
                }
                if (err.status === 404){
                    let errorObject = this.CreateErrorResponseObject(err, "The requested resource could not be found.")
                    return throwError(errorObject);
                }
                if (err.status === 500){
                    let errorObject = this.CreateErrorResponseObject(err, "Server error occurred, please contact the site administrator.")
                    return throwError(errorObject);
                }
                //An error occurred of some other status code

                let errorObject = this.CreateErrorResponseObject(err);
                return throwError(errorObject);
            }
        }))
    }

    private CreateErrorResponseObject(input:HttpErrorResponse , defaultErrorMsg:string = ""){
        return new ReceivedError(input, defaultErrorMsg);
    }
}

export class ReceivedError{
    Code : number;
    Message : string;

    constructor(input:HttpErrorResponse, defaultErrorMsg:string = "") {
        this.Code = input.status;
        this.Message = input.error.message || defaultErrorMsg;
    }
}



