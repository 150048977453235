import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UploadService} from "@/_services/UploadService";
import {ReportDto, ReportsService} from "@/_services/reports.service";
import {AbstractUploadComponent} from "@/_components/AbstractUploadComponent";

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['../../../../app.component.css', "../../../_forms/forms.css", "../../../dragula.css"],
  providers: [{provide: UploadService, useClass: ReportsService}],
  encapsulation: ViewEncapsulation.None
})
export class AddReportComponent extends AbstractUploadComponent implements OnInit {
  NewReport:ReportDto;

  constructor(
      protected reportService:UploadService,
      protected route: ActivatedRoute,
  ) {
    super(reportService);
  }

  ngOnInit() {
    this.SetLoadingState();
    this.InstantiateNewReport();
    this.SetFinishedLoadingState();
  }

    protected GetResponseValue(response: any) {
        return response ? response.value : null;
    }


    private InstantiateNewReport() {
    this.NewReport = new ReportDto();
    this.NewReport.category = this.route.snapshot.paramMap.get('category');
    this.NewReport.description = "";
  }

  SetFile(event){
    this.NewReport.File = event.target.files[0];
  }

  SubmitUpload(){
    this.ResetState();
    this.SetLoadingState();
    super.SubmitFile(this.NewReport).then(()=>{
      this.SetStatusMessage("Report added successfully.")
    }).finally(()=>{
      super.SetFinishedLoadingState();
    });
  }
}
