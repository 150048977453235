import { HttpClient } from '@angular/common/http';
import {Observable, of} from "rxjs";
import {IContentPage, IMenuLink} from "@/_models/IContent";
import {Injectable} from "@angular/core";
import {AbstractService} from "@/_services/AbstractService";
import {ISimplePage} from "@/_models/SimplePage";

@Injectable({ providedIn: 'root' })
export class ContentService extends AbstractService{
    private siteNamesObservableCache: Observable<Array<string>>;
    //private siteNamesObservableCache: { [key: number]: Observable<Array<string>> } = {};

    constructor(protected http: HttpClient) {
        super(http, "pages");
    }

    public GetExistingPages() : Observable<IMenuLink[]>{
        return this.http.get<IMenuLink[]>(`${this.apiURL}all`);
    }

    public GetSinglePage(id:string) : Observable<IContentPage>{
        return this.http.get<IContentPage>(`${this.apiURL}id/${id}`);
    }

    public GetSingleLink(id:string) : Observable<IMenuLink>{
        return this.http.get<IMenuLink>(`${this.apiURL}id/${id}`);
    }

    public UpdateLink(value:IMenuLink)  : Observable<void>{
        return this.http.patch<void>(`${this.apiURL}link/id/${value._id}`, value);
    }

    public AddLink(value:IMenuLink): Observable<IMenuLink> {
        return this.http.post<IMenuLink>(`${this.apiURL}link/new`, value);
    }

    public GetSubSiteNames() : Observable<string[]>{
        return this.http.get<string[]>(`${this.apiURL}sites`);
    }

    public UpdatePage(value:IContentPage)  : Observable<void>{
        return this.http.patch<void>(`${this.apiURL}content/id/${value._id}`, value);
    }

    public AddPage(value:IContentPage): Observable<IContentPage> {
        return this.http.post<IContentPage>(`${this.apiURL}page/new`, value);
    }

    public GetSimplePages() : Observable<ISimplePage[]>{
        return this.http.get<ISimplePage[]>(`${this.apiURL}structure`);
    }

    public ReorderPages(values:IMenuLink[]) : Observable<void>{
        return this.http.post<void>(`${this.apiURL}order`, values);
    }

    public DeleteItem(value:IMenuLink) : Observable<any>{
        return this.http.delete<void>(`${this.apiURL}${value._id}` );
    }
}