import {HttpClient, HttpEvent} from '@angular/common/http';
import {UploadService} from "@/_services/UploadService";
import {Observable} from "rxjs";

export class ImageService extends UploadService{
    constructor(protected http: HttpClient) {
        super(http,"files");
    }

    public UploadFile(values:FormData){
        return this.http.post<string>(`${this.apiURL}`+'image', values);
    }
}