import {Component} from '@angular/core';
import {AbstractPopup} from "@/_components/_forms/abstract-popup";

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent extends AbstractPopup<boolean,DeleteConfirmationComponent> {
    ngOnInit() {
        super.ngOnInit();
        this.local_data = false;
    }

    SubmitEnabled(){
        return this.local_data;
    }

}

