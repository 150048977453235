import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {AbstractService} from "@/_services/AbstractService";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class DownloadService extends AbstractService{
    constructor(protected http: HttpClient) {
        super(http,"downloads");
    }

    public DownloadDetails() : Observable<HttpEvent<Blob>>{
        return this.DownloadFile('details');
    }

    public DownloadFile(path: string): Observable<HttpEvent<Blob>> {
        return this.http.request(new HttpRequest(
            'GET',
            `${this.apiURL}${path}`,
            null,
            {
                //reportProgress: true,
                responseType: 'blob'
            }));
    }
    /*public DownloadDetails(values:FormData){
        this.http.get(`${this.apiURL}details`).subscribe(data=>this.DownloadFile()), error=>;
    }*/

    /*DownloadFile(data: Response) {
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        window.open(url);
    }



    GetCurrentTaxonomyFile() {
        const options = {responseType:'blob' as 'blob'};
        this.http.get<Blob>(`${this.apiURL}download_current_taxonomy`,{responseType: 'blob' as 'json' }).subscribe(
            response => {
                //console.log("got response");
                //console.log(response);
                //return response;
                this.downLoadFile(response)
            },
            error=>{
                //console.log("error");
            });



    }*/



}