import {AbstractDisplayComponent} from "../AbstractDisplayComponent";
import {ConfigService} from "@/_services";
import {ISetting} from "@/_models";

export abstract class AbstractSiteSettingsComponent extends AbstractDisplayComponent{

    protected constructor(
        protected configService:ConfigService) {
        super();
    }

    GetObservableValue(name:string) {
        return this.configService.Get(name);
    }

    SubmitValue(value:ISetting){
        return this.ProcessServerRequest(this.configService.Set(value.title,value.value)).then(result=>{
            this.SetStatusMessage("Setting updated.");
        }).catch(error=>{
            this.AfterErrorReceived(error, "Error updating setting.")
        }).finally(()=>{

        });
    }
}
