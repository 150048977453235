import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApplyOrderPositions, ITaxonomyVersionReceive, ITaxonomyVersionSubmit, Sort, TaxonomyVersionPair} from "@/_models/TaxonomyVersion";
import {TaxonomyVersionService} from "@/_services/taxonomyVersion.service";
import {AbstractDisplayComponent} from "../../AbstractDisplayComponent";
import {DragDropHelper} from "@/_services/drag-drop.helper";

@Component({
    selector: 'app-taxonomy-version-list',
    templateUrl: './taxonomy-version-list.component.html',
    styleUrls: ['./taxonomy-version-list.component.css', "../../_forms/forms.css", "../../dragula.css"]
})
export class TaxonomyVersionListComponent extends AbstractDisplayComponent implements OnInit {
    Versions: TaxonomyVersionPair[];
    SelectedVersion: TaxonomyVersionPair;
    ReorderingList: ITaxonomyVersionSubmit[];

    @Output() SetSelectedVersion: EventEmitter<any> = new EventEmitter();

    constructor(
        public dragDropper: DragDropHelper,
        protected taxonomyVersionService: TaxonomyVersionService)
    {
        super();
    }

    ngOnInit() {
        this.LoadContent().then(result=>{
            this.CreateDataConstruct(result);
        }).catch(error=>{
            this.AfterErrorReceived(error);
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });
    }

    handleVersionSelection(event, id) {
        let target = event.target;
        if (target.checked) {
            if(this.SelectedVersion){
                this.SelectedVersion.selected = false;
            }
            this.SelectedVersion = this.Versions.find(x => x.received._id == id);
            this.SetSelectedVersion.emit(this.SelectedVersion);
            this.SelectedVersion.selected = true;
        }
    }

    LoadContent() : Promise<ITaxonomyVersionReceive[]> {
        this.SetLoadingState();
        return new Promise<ITaxonomyVersionReceive[]>((resolve, reject) =>{
            this.taxonomyVersionService.GetAllVersions().subscribe(versions => {
                    resolve(versions);
                }
                , error => {
                    reject(error);
                })
        });
    }

    private CreateDataConstruct(versions:ITaxonomyVersionReceive[]){
        this.Versions = new Array<TaxonomyVersionPair>();
        this.ReorderingList = new Array<ITaxonomyVersionSubmit>();
        versions = Sort(versions)
        versions.forEach(version => {
            this.Versions.push(new TaxonomyVersionPair(version));
        });
    }

    UpdateVersionOrder(event) {
        this.Versions = event;
        this.Versions.forEach(x => {
            this.ReorderingList.push(x.submitting);
        });

        ApplyOrderPositions(this.ReorderingList);
    }

    private ResetSelectionOnPreviouslySelected(){
        let previouslySelected = this.SelectedVersion.submitting._id;
        this.SelectedVersion = this.Versions.find(x=>x.received._id == previouslySelected);
    }

    TransmissionComplete() {
        this.LoadContent().then(()=>{
            this.ResetSelectionOnPreviouslySelected();
        }).catch(()=>{
            this.SelectedVersion = null;
        }).finally(()=>{
            this.SetFinishedLoadingState();
        });
    }

    ReloadContent(){
        super.Submit();
        this.LoadContent().then(()=>{

        }).catch(error=>{
            this.AfterErrorReceived(error);
        }).finally(()=>{
            this.TransmissionComplete();
        })
    }

    Submit(){
        super.Submit();
        this.SetLoadingState();
        this.SubmitToServer().then(()=>{
            super.SetStatusMessage("Order updated");
        }).catch(error=>{
            this.AfterErrorReceived(error);
        }).finally(()=>{
            this.TransmissionComplete();
        })
    }

    SubmitToServer(): Promise<void> {
        return new Promise<void>((resolve, reject) =>
            this.taxonomyVersionService.UpdateVersionOrder(this.GetSubmissionValues()).subscribe(() => {
                resolve();
            }, error => {
                reject(error);
            }));
    }

    private GetSubmissionValues() {
        let submitting = new Array<ITaxonomyVersionSubmit>();
        this.Versions.forEach(x => submitting.push(x.submitting));
        return submitting;
    }
}
